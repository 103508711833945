.boldLabel {
   font-size: 27px;
   font-weight: 500;
   text-align: left;
}

.muiTabs {
   font-weight: 500;
   font-size: 18px !important;
   text-transform: none !important;
   padding: 20px 25px !important;
   color: #222222 !important;
}

.muiTabs.Mui-selected {
   color: #232323 !important;
}

.css-1aquho2-MuiTabs-indicator {
   height: 12px;
   background-color: #ffeb3b !important;
}

.categoryOutsideBox {
   background-color: transparent;
   height: 350px;
   width: 20%;
   display: flex;
   justify-content: center;
   align-items: center;

   .categoryOutsideBox {
      .MuiSkeleton-text {
         transform: scale(1, 1);
      }
   }
}

.categoryBox {
   cursor: pointer;
   background-color: #fff;
   border-radius: 12px;
   height: 90%;
   width: 85%;
   background-color: white;
   border-style: solid;
   border-radius: 12px;
   border-width: 1px 1px 1px 1px;
   border-color: #c7cad9;
}

.categoryImage {
   border-radius: 12px;
   width: 100%;
   height: 220px;
   // background-color: #ffeb3b;
   background-color: #bdbdbd;
   font-weight: 600;
   display: flex;
   align-items: center;
   justify-content: center;
   object-fit: cover;
}

.categoryImage span {
   width: 100%;
   height: 100%;
   background-size: unset;
}

.categoryImage img {
   border-radius: 12px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.categoryName {
   background-color: #fff;
   height: 90px;
   padding: 10px;
   display: flex;
   border-radius: 0px 0px 12px 12px;
   justify-content: center;
   align-items: center;
   text-align: center;
   font-weight: 600;
   font-size: 16px;
}

.categoryName span {
   background-color: #fff;
}

.categorySubLabel {
   display: flex;
   height: 60px;
   align-items: flex-end;
   justify-content: left;
   padding-left: 20px;
}

.categorySubLabel span {
   font-size: 20px;
   font-weight: 500;
}

.roomTypesBox {
   background-color: #fff;
   width: 100%;
   height: fit-content;
   margin: 20px;
   border: 1px solid #c7cad9;
   border-radius: 15px;
}

.roomTypesLabel {
   display: flex;
   background-color: #fff;
   border-radius: 15px 15px 0px 0px;
   border: 1px solid #c7cad9;
   padding: 16px;
   font-size: 22px;
   font-weight: 500;
   justify-content: left;
   align-items: center;

}

.roomTypesCheckbox {
   background-color: transparent;
   padding: 20px;
   display: flex;
   border: 1px solid #c7cad9;
}

.roomTypesCheckbox:nth-last-child(1) {
   border: none !important;
}

.nameIconBox {
   background-color: transparent;
   display: flex;
   align-items: center;
   font-size: 18px;
   font-weight: 400;
   text-align: left;
   width: 100%;
}


.nameIconBox span,
.nameIconBox svg {
   margin-left: 15px;
   background-color: #fff;
   font-weight: 500;
}

.checkboxBox {
   background-color: transparent;
}

.tab {
   padding: 0px 10px;
   transition: .5s;
   flex-wrap: nowrap !important;
   text-align: center;
   height: 50px;
   line-height: 50px;
   font-weight: 500;
   cursor: pointer;
   font-size: 18px;
}

.tab.active {
   transition: .5s;
   border-bottom: 3px solid #ffeb3b;
   cursor: none;
}

.subCategoryWrapper .MuiGrid-container {
   justify-content: flex-start;
}

.lazyLoad {
   img {
      width: 50px;
      height: 50px;
   }
}

.NoRoomClassBox {
   margin-top: 50px;
   margin-bottom: 50px;
   width: 100%;
   height: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   .createRoomButtonBox {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .contained-btn {
         padding: 8px 30px !important;
      }
      span {
         font-size: 16px !important;
      }
   }
 }

 .NoRoomClassBox .secondText {
   text-align: center;
   font-size: 16px;
   color: #bdbdbd;
 }
 
 .NoRoomClassBox span {
   font-weight: 500;
   font-size: 20px;
   margin-top: 5px;
   color:#0F0F0F;
 }
 
 .NoRoomClassBox img {
   width: 42px;
   height: 42px;
 }

@media only screen and (max-width: 900px) {
   .boldLabel {
      max-width: 275px !important;
      font-size: 18px;
      font-weight: 500;
      text-align: left;

      span {
         display: none;
         // display: block;
         // width: max-content !important;

      }
   }

   .tabDiv {
      gap: 20px;
      width: 100% !important;
      flex-wrap: nowrap !important;
      overflow-x: scroll !important;
      overflow-y: none !important;
   }

   .tabDiv::-webkit-scrollbar {
      display: none !important;
   }

   .tab {
      white-space: nowrap !important;
      // width: max-content;
      color: #c7cad9;
      max-width: max-content !important;

      &.active {
         color: #222222;
      }
   }

   .lazyLoad {
      min-height: fit-content;
   }

   .tabList {
      gap: 10px;
      max-width: 100% !important;
      width: 100% !important;
      flex-wrap: nowrap !important;
      &.profile {
         border-bottom: 1px solid #bdbdbd;
      }
   }

   .categoryOutsideBox {
      width: 50% !important;
      height: 233px;
      margin-bottom: 10px;

      .categoryBox {
         cursor: pointer;
         background-color: #fff;
         border-radius: 12px;
         height: 90%;
         width: 85%;
         background-color: white;
         border-style: solid;
         border-radius: 12px;
         border-width: 1px 1px 1px 1px;
         border-color: #c7cad9;
      }

      .categoryImage {
         width: 100%;
         height: 150px;
      }

      .categoryName {
         height: 55px;
         padding: 3px;
         font-size: 13px;
      }

      .categoryName span {
         background-color: #fff;
      }
   }
}