/******paper********/
#sidebar {
  width: 30%;
  border: 0 !important;
}

#sidebar .paper {
  margin: 1px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.paper .MuiPaper-rounded {
  border-radius: 15px;
}

.paper .tab-label {
  width: 50%;
  font-size: large;
  font-weight: bold;
  color: #262556;
}

/***********tabs**********/

.MuiTabs-indicator {
  display: none;
}

.MuiTab-wrapper {
  text-transform: none;
  margin: 6px 0;
}

.active-tab .MuiTab-wrapper {
  background-color: #f5f5f7;
  padding: 6%;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 500;
}

.disabled-tab .MuiTab-wrapper {
  color: #c0bfbf;
  font-size: 1rem;
  font-weight: 500;
}

/***tab****/
#tab .MuiBox-root {
  padding: 0 !important;
  text-align: left;
}
#tab .MuiTabs-flexContainer {
  justify-content: space-evenly;
}

/*****participantTab*****/
#participants {
  border-radius: 0 0 8px 8px !important;
}

#participants .MuiCardContent-root {
  padding-bottom: 0;
}

#participants .label {
  font-weight: 500;
  color: #262556;
  font-size: 1rem;
}

.userdata-and-hearts .MuiAvatar-root {
  width: 56px;
  height: 56px;
  border-radius: 15px;
}

.userdata-and-hearts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-username {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-avatar {
  margin-right: 10px;
}

.user-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#heart {
  color: #ffcb00;
}

#heart .MuiRating-label {
  padding: 0 3px;
}

#heart .MuiRating-iconActive {
  transform: none;
}

.x-field {
  margin: 0.4rem 0 0.8rem;
}

.x-field .MuiOutlinedInput-root {
  border-radius: 15px;
  font-size: 0.9rem;
}

#connection-button {
  color: white;
  padding: 10px 0;
  border-radius: 15px;
  font-size: 1rem;
  letter-spacing: 0.15rem;
}

#participants .MuiCardActions-root {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
}

/***message Tab**/
#message-tab {
  height: 81vh;
  overflow: auto;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-start; */
}

/****message Text**/
.other-message-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  word-break: break-all;
}

.other-message-body-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #c0bfbf;
  border-radius: 20px;
  padding: 5px 12px 5px 12px;
  color: #2d2e5f;
  font-size: 0.9rem;
}

.your-message-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
}

.your-message-body-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #e7f1ff;
  border-radius: 20px;
  padding: 5px 12px 5px 12px;
  color: #2d2e5f;
  font-size: 0.9rem;
}

.time-stamp {
  font-size: 0.75rem;
}

.fade {
  color: #c0bfbf;
}

/****message-footer**/
.message-box {
  width: 100%;
  margin: 6px 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 5px 0;
}

.fade-back {
  background-color: #f5f5f7;
}

.message-tool {
  border-radius: 15px;
  text-align: center;
}

.message-tool button {
  padding: 11px !important;
  min-width: 30px;
  border-radius: 10px !important;
}

.text-message .MuiOutlinedInput-root {
  border-radius: 8px;
}

#text-message {
  padding: 15px;
  font-size: 0.9rem;
}

.epr-emoji-category .epr-emoji-category-label {
  display: none !important;
}

#emoji-picker aside.EmojiPickerReact.epr-main {
  border: none;
}

@media screen and (min-width: 1100px) and (max-width: 1250px) {
  .active-tab {
    padding-right: 3%;
    padding-left: 3%;
  }
  .disabled-tab {
    padding-right: 3%;
    padding-left: 3%;
  }
  #connection-button {
    padding: 10px 0 !important;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
  }
  #tab .MuiTab-root {
    padding: 6px 4px;
  }
}

@media screen and (min-width: 500px) and (max-width: 1101px) {
  #sidebar {
    width: 95%;
  }
  #tab .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 500px) {
  .active-tab {
    padding-right: 2%;
    padding-left: 2%;
  }
  .disabled-tab {
    padding-right: 2%;
    padding-left: 2%;
  }
  .paper .tab-label {
    font-size: 0.8rem;
  }
  #sidebar {
    width: 95%;
  }
  #tab .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
  #connection-button {
    font-size: 0.8rem;
    letter-spacing: 0;
  }
}

.resource-links div {
  cursor: pointer;
}

.resource-links textarea {
  cursor: pointer;
}

.resource-links a div div {
  border-radius: 10px !important;
  padding: 15px 12px;
}

.resource-links:not(:last-child) {
  margin-bottom: .5rem;
}