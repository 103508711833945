html,
body,
#root,
#root > div {
  overflow-y: auto;
}

.menu-list > ul {
  margin-bottom: 0px !important;
}

.nav-menus {
  padding-bottom: 10px;
}

.nav-menus:hover {
  cursor: pointer;
  border-bottom: 1px solid #ffcb00;
}

.enter-btn {
  background: #ffcb00 !important;
}

.desc-syl {
  color: #b5b3be;
  font-size: 20px;
}

#para-syl {
  color: #000;
}

.store-btn {
  width: 10px;
  height: 40px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  width: 350px;
  background-color: #ffcb00;
  color: #010039;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -130px;
  left: -2px;
  font-size: 18px;
  padding: 16px 8px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcb00 transparent transparent transparent;
}

.dotted-line-vertical {
  position: relative;
  width: 20px;
}

.dotted-line-vertical::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}
.dotted-line-vertical::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}
