@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap);
.messenger {
  height: calc(100vh-70px);
  display: flex;
}

.chatMenuWrapper,
.chatBoxWrapper {
  margin: 20px;
  font-weight: 450;
  font-size: 20px;
}

.profilelist {
  padding-left: 15px;
}

.createicon {
  margin-top: 25px;
  font-size: 15px;
  margin-left: 160px;
}

.verticalline {
  border-right: 2px solid gray;
  height: 500px;
}

.conversation {
  display: flex;
  padding: 10px;
}

.messages {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop {
  display: flex;
}

.messageText {
  padding: 10px;
  border-radius: 20px;
  background-color: #1877f2;
  color: white;
  max-width: 300px;
}

.chatuserprofile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}

.chatBoxBottom {
  box-shadow: 0px 8px 32px rgba(39, 39, 39, 0.24);
  width: 73%;
  margin-left: 2rem;
  border-radius: 32px;
  display: flex;
  align-items: center;
  height: 3rem;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  bottom: 0;
}

.sendbutton {
  background: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-left: auto;
  padding-right: 0.7rem;
  transition: all 0.3s ease;
}

.sendbutton:hover {
  scale: 1.1;
}

.sendbutton:active {
  scale: 0.8;
}

.sendbutton:disabled {
  pointer-events: none;
}

.chattextinput {
  margin-left: 50px;
  margin-top: 5px;
  width: 90%;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  border: none;
  outline: none;
}

.chatboxbottomimg {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 6px;
  position: absolute;
  z-index: 1000;
}

.messageBottom {
  font-size: 12px;
  margin-top: 10px;
}

.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}

.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 20px;
}

.conversationName {
  padding-top: 5px;
  font-weight: 400;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: 1 px solid gray;
}

/* search input CSS */
.search_input {
  font-weight: 600;
}

.messages {
  height: 82%;
}

.isTyping {
  height: 78%;
}

/* media query */
@media screen and (max-width: 1650px) {
  .chatBoxBottom {
    width: 70vw;
  }
}

@media screen and (max-width: 1450px) {
  .chatBoxBottom {
    width: 67vw;
  }
}

@media screen and (max-width: 1250px) {
  .chatBoxBottom {
    width: 62vw;
  }
}

@media screen and (max-width: 1000px) {
  .chatBoxBottom {
    width: 56vw;
  }
}

@media screen and (max-height: 1000px) {
  .messages {
    height: 78%;
    margin-top: 0;
  }

  .isTyping {
    height: 76%;
  }
}

@media screen and (max-height: 850px) {
  .messages {
    height: 75%;
  }

  .isTyping {
    height: 73%;
  }
}

@media screen and (max-height: 750px) {
  .messages {
    height: 73%;
  }

  .isTyping {
    height: 68%;
  }
}

@media screen and (max-height: 650px) {
  .messages {
    height: 68%;
  }

  .isTyping {
    height: 64%;
  }
}

@media screen and (max-height: 600px) {
  .messages {
    height: 62%;
  }

  .isTyping {
    height: 54%;
  }
}
/* * {
  padding: 0;
  margin: 0;
  font-family: "Baloo Bhai 2", cursive;
  overflow-x: hidden;
} */
.moderator-wrapper {
  padding-top: 90px;
  background-image: url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  background-position: 40px 28px, 170px 1599px, 1394px 768px;
  background-size: 40px 28px, 170px 1599px, 1394px 768px;
}

.moderator-wrapper-connection {
  background-position: 10px 40px, 110% 700px !important;
  background-size: 500px 500px, 500px 500px !important;
}

.moderator-wrapper .categories-aside {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  background: #fff;
}

.moderator-wrapper .sidebar {
  box-sizing: border-box !important;
  max-height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
  overflow-x: auto !important;
  padding: 0px !important;
  margin-top: 12px;
  position: sticky;
  top: 66px;
}
.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: lightgrey !important;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #0000008f;
  border-radius: 5px;
}

.moderator-wrapper .categories-head {
  padding: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7cad9;
  font-size: 18px;
}

.moderator-wrapper div.buttons-div-moderator {
  padding-bottom: 15px !important;
}

.moderator-wrapper .buttons-div-moderator.bBottom .MuiButton-contained {
  height: 30px !important;
}

.moderator-wrapper .categories-head * {
  margin: 0px;
}

.moderator-wrapper .daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p15 {
  padding: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

/* .mb20 {
  overflow:hidden
}
.mb30 {
  margin-bottom: 30px;
} */

/* .moderator-wrapper .bBottom {
  border-bottom: 1px solid rgb(192, 192, 192);
} */

.moderator-wrapper .data {
  padding: 15px;
  /* border-bottom: 1px solid rgb(192, 192, 192); */
}
.moderator-wrapper .daf.bBottom:last-child {
  border-bottom: none !important;
}

.moderator-wrapper .daf.bBottom:first-child {
  border-top: 1px solid #c7cad9;
}

.selected-category {
  background: #fffae6;
}

.flex-sb-center {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.flex-sb {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
/* .bBottomYellow {
  border-bottom: 2px solid #ffcb00;
} */

.pointer {
  cursor: pointer;
}

.moderator-wrapper .buttons-div-moderator {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px !important;
}

.moderator-wrapper .btn-Category-inner {
  box-shadow: none !important;
  background: #ffcd2f !important;
}

.moderator-wrapper .btn-age {
  width: 100%;
  justify-content: start !important;
  padding-left: 20px;
  /* border-bottom: 1px solid #E5E5E5; */
}

.moderator-wrapper .btn-age.MuiButton-root {
  border-radius: 0px !important;
}

.moderator-wrapper .btn-age-outter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 1px;
  border-top: 1px solid #e5e5e5;
}
.moderator-wrapper .btn-age {
  padding: 10px 15px !important;
}

.moderator-wrapper .heading-moderator {
  font-size: 25px;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 15px;
}

.moderator-wrapper .list-item-outter {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
}
.moderator-wrapper .list-first-div {
  margin-top: 12px;
}

.moderator-wrapper .list-first-div .span-after-username {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00000050 !important;
}

.moderator-wrapper .list-first-div > span {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #010138;
}

.moderator-wrapper .list-second-div {
  color: #00000050;
}

.moderator-wrapper .rooms {
  padding: 0px 5px;
}

.moderator-wrapper .rooms svg {
  font-size: 11px;
  /* padding: 0px 7px 0px 16px; */
  position: relative;
  margin: 0px 5px;
  top: 1px;
  color: #00000030;
}

.moderator-wrapper .list-item-content p {
  color: #c1c1c1;
  font-weight: 500;
  width: 80%;
  padding-top: 10px;
}

.moderator-wrapper .list-item-outter button {
  background: #010138;
  color: #fff;
  border: 1px solid #000;
  border-radius: 30px !important;
  padding: 5px 20px;
}

.moderator-wrapper .buttons-div-moderator.null {
  background: unset;
  box-shadow: none;
  border-radius: unset;
}

.icon-style-connection {
  height: 40px;
  width: 40px;
  right: 200px;
  position: absolute;
  top: 20px;
  cursor: pointer;
}

.message-icon-style-connection {
  height: 18px;
  width: 18px;
  right: 210px;
  position: absolute;
  top: 32px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .moderator-wrapper {
    padding: 2rem;
  }
}

.mainContainer {
  display: flex;
  background-color: #fcfbfc;
  padding-top: 80px;
  background: #fcfbfc;
  height: 100%;
}
.mainContainer .scrollbarY.left {
  padding: 0px;
}
.mainContainer .scrollbarY.right {
  height: calc(100vh - 110px);
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.mainContainer .mainMiddle {
  padding: 0px 20px;
}

.column1-main-box {
  text-align: center;
  padding: 1px 10px;
}

.column1-child-box1 {
  margin: auto;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

.tick-image {
  background-color: #010039;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  z-index: -1;
}

.connectionbox {
  width: 90%;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

@media (max-width: 850px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}
@media (max-width: 450px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}
.penIcon {
  width: 16px;
  height: 16px;
  /* padding-left: -10px; */
  margin-right: 18px;
  cursor: pointer;
}

.penIcon2 {
  width: 16px;
  height: 16px;
  /* padding-left: 10px; */
  margin-right: 12px;
  cursor: pointer;
}

#profilePic {
  width: 70px;
  height: 70px;
}

#profilePic0 {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.profileChildIcon {
  width: 18px;
}

.hearts {
  margin-top: 10px;
  color: #c4c6cb;
}

.hearts1 {
  margin-top: 15px;
  color: #c4c6cb;
}

.hearts2 {
  margin-top: 10px;
  color: #010039;
  font-weight: 700;
}

.column1-child-box1-clild1 {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

#profile-name {
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  color: #010039;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider0 {
  margin-top: -10px;
}

.slider0 .MuiSlider-track {
  height: 4px;
  display: block;
  position: absolute;
  border-radius: 3px;
  background-color: #fbca0c;
  color: #d9d9d9;
}

.slider0 .MuiSlider-rail {
  width: 100%;
  height: 4px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 4px;
  background-color: gray;
}

.slider0 .MuiSlider-thumb {
  position: initial !important;
}

.subcategories-box {
  justify-content: space-between;
  width: 100%;
}

#UserPersonalProfile-connected-btn {
  background-color: #010138;
  color: ffffff;
  height: 35px;
  border-radius: 16px;
  width: 100px;
  border: none;
}

.touch-points {
  color: #010138;
  font-weight: 500;
  cursor: pointer;
}

/* //middle box css */
.middle-box {
  border-radius: 15px;
  border: 1px solid #dbd8d8;
  background-color: white;
}

.documentsBox {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.documentLinks {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  max-width: 200px;
  transition: 0.5s;
}

.documentLinks a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #fbca0c;
  border-radius: 8px;
  padding: 5px 10px;
  background-color: #fbca0c;
  transition: 0.5s;
  font-weight: 600;
  color: #010138;
}

.documentLinks:hover a {
  cursor: pointer;
  color: #010138;
  background-color: #fff;
}

.mainTopStatistics {
  text-align: center;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}
.mainTopStatistics .label {
  font-size: 15px;
  color: rgb(189, 189, 191);
  font-weight: 500;
}
.mainTopStatistics .count {
  font-weight: 600;
}

#middel-box-lable {
  word-break: break-word;
  font-size: 16px;
  font-weight: 500;
  color: #010039;
}

#middel-box-text {
  font-size: 16px;
  font-weight: 600;
  color: #787878;
}

/* THird column */
.right-side-btn {
  text-transform: none;
  margin-top: 30px;
  width: 90%;
  border: 1px solid #dbd8d8 !important;
  /* margin-left: 10px !important; */
}

.right-side-btn-div .MuiButton-root {
  border-radius: 12px !important;
  padding: 16px 16px 16px 10px !important;
  background: #fff !important;
}

.column1-main-box {
  padding: 1px 30px;
  padding-left: 0px;
}

@media (max-width: 850) {
  .column1-main-box {
    padding: 1px 10px;
  }
}
@media (max-width: 450) {
  .column1-main-box {
    padding: 1px 5px;
  }
}
.column2-child-box2 {
  width: 90%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbarY {
  height: 90vh;
  overflow-y: auto;
}

.messageIconButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 35px;
}

.touch-points-profile {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.touch-points-profil img {
  width: 25%;
}

.touch-points-profile .touch-points-profile-name {
  text-align: left;
  min-width: 30%;
  flex: 1 1;
}

.touch-points-profile .touch-points-profile-name p:first-child {
  color: #010039;
  font-weight: 500;
  cursor: pointer;
}

.touch-points-profile .touch-points-profile-name p:last-child {
  color: #8e8e8e;
  font-size: 12px;
}

.touch-points-profile .touch-points-profile-action button {
  /* cursor: pointer; */
  border: none;
  transition: all 0.3s ease-in-out;
}

/* .touch-points-profile .touch-points-profile-action button:hover {
  scale: 1.05;
}

.touch-points-profile .touch-points-profile-action button:active {
  background: #010014;
  scale: 0.9;
} */
.touch-points-profile .touch-points-profile-action button:disabled {
  pointer-events: none;
  background-color: #787878 !important;
  color: #fff !important;
}

.touch-points-profile .touch-points-profile-action button span {
  font-size: 12px;
}

.user-card__image-container {
  position: relative;
}

.user-card__check-mark {
  position: absolute;
  bottom: 3px;
  right: 12px;
  height: 20px;
}

.upcoming-room_check-mark {
  position: absolute;
  bottom: 0;
  right: -2px;
  height: 20px;
}

.search-bar_check-mark {
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 20px;
}

.video-room_check-mark {
  position: absolute;
  bottom: 0px;
  right: 5px;
  height: 20px;
}

.room-details_check-mark {
  position: absolute;
  bottom: -3px;
  right: 6px;
  height: 15px;
}

.touch-points-user-container .touch-points-profile {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #ccc;
}

.Heading-moderator {
  color: #010039;
}

.back-arrow-btn {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .mainTopStatistics {
    text-align: center;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    width: 50%;
  }
  .mainTopStatistics .label {
    font-size: 15px;
    color: rgb(189, 189, 191);
    font-weight: 500;
  }
  .mainTopStatistics .count {
    font-weight: 600;
  }
  .moderator-wrapper.moderator-wrapper-connection .followerList {
    padding-top: 50px;
    flex-direction: column;
  }
  .mainContainer {
    padding: 20px 2px;
  }
  .mainContainer .scrollbarY {
    height: 100%;
    overflow: hidden;
  }
  .mainContainer .scrollbarY.left {
    display: contents;
  }
  .mainContainer .mainMiddle {
    padding: 20px 0px;
  }
  .mainContainer .column1-main-box.profile {
    width: 100%;
    padding: 0px;
    padding-top: 50px;
  }
  #middel-box-lable {
    text-align: left;
  }
}
.MuiAlert-root .MuiSvgIcon-root{
    color: black !important;
}
* {
    font-family: "Poppins" !important;
}

.react-tel-input {
    margin-top: 5px;
}

.cursor {
    cursor: pointer;
}

.react-tel-input .form-control {
    width: 100%;
}

.main-container {
    width: 1000px;
    border-radius: 15px;
    margin: 60px auto;
    overflow: hidden;
    height: -moz-max-content;
    height: max-content;
}

.img {
    width: 100%;
    margin: 0px auto;
}

.cancel-icon {
    margin: 25px !important;
    height: 45px;
    width: 45px;
    font-size: 35px !important;
    cursor: pointer;
}

.main-div {
    padding: 40px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.cancel-out {
    position: absolute;
    right: 0px;
    z-index: 1;
}

.heading_signup {
    padding: 0px;
    font-size: 36px;
    margin-top: 0px;
    font-weight: 500 !important;
}

.h2 {
    padding-bottom: 10px;
    font-size: 36px;
    margin-top: 0px;
}

.label {
    font-weight: 500;
    color: #150c44 !important;
}

.labul {
    margin-left: 20px;
    font-weight: 400;
    color: #7F8E9D;
}

.error {
    margin-left: 10px;
    color: red;
    display: block;
    font-size: 12px;
    font-weight: 500;
    min-height: 18px;
}

.checkbox-div {
    display: flex;
    /* align-items: flex-start; */
    /* margin-bottom: 20px; */
    /* flex-direction: column; */
    justify-content: center;
}

/* .checkbox {
    width: 18px;
    height: 30px;
    vertical-align: middle;
    margin-right: 7px;
} */

.terms {
    font-size: 13px;
    font-weight: 500;
    color: #97a4af;
}

.a1 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

/* .checkbox input{
    border-radius: 50% !important;
} */

.div-container .textField {
    margin: 9px 0px 25px !important;
    border-radius: 10px !important;
}

.checkbox-div .container {
    display: block;
    position: relative;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-bottom: 6px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 1;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: solid 1px #000000;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkbox-div input[type="checkbox"]::after {
    left: 27%;
    top: 11%;
    width: 37%;
    height: 69%;
}

.a2 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

.btn {
    width: 100%;
    color: #fff !important;
    background: #150c44 !important;
    font-size: 15px;
    margin: 15px 0px;
    border-radius: 10px;
    box-shadow: unset;
    text-transform: unset;
}

.h4 {
    margin: 30px;
    font-size: 20px;
    font-family: none;
    color: #00000059;
}

.a3 {
    color: #150c44;
    font-weight: bold;
}

.grid-image {
    background: #FFCB00;
    display: flex;
    align-content: center;
    align-items: center;
}

.image-logo {
    text-align: center;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 10px !important;
}

.MuiButton-root {
    text-transform: none !important;
    border-radius: 10px !important;
}

.tabsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabsButtons {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
}

.tabButton {
    width: 12px;
    height: 12px;
    background: #C7CAD9;
    border-radius: 100%;
    margin: 0 8px;
    transition: background-color 0.3s ease;
}

.tabButton:hover {
    cursor: pointer;
    background-color: #eee;
}

.tabButton.active {
    width: 16px;
    height: 16px;
    background-color: #010039;
}

.tabsContent {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    transition: transform 0.5s ease;
    height: 100%;
    position: relative;

}

.tabContent {
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: 0.5s;
}

.tabContent p {
    text-align: center;
    width: 100%;
}

.tabContent.small {
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(200%);
}

.tabContent.big {
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200%);
}

.tabContent.active {
    overflow: hidden;
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    position: relative;
}
.dropdown-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  min-width: 120px;
}

.pointer {
  cursor: pointer;
}

.dropdown-container * {
  width: 100%;
  box-sizing: border-box;
}

.dropdown-value {
  padding: 10px;
  font-weight: 400;
}

.dropdown-content-border {
  border-bottom: solid 1px #00000030;
}

.dropdown-content {
  padding: 10px;
  color: #aaa;
  font-weight: 400;
}

.dropdown-content:hover {
  background: #ccc;
}

.border-grey {
  border: 1px solid #00000030;
}

.dropdown-border {
  border: solid 1px #00000030;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-content-container {
  cursor: auto;
  color: #27272A;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 120px;
  width: 100%;
  z-index: 1;
  border: solid 1px #00000030;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  /* top: 40px; */
  left: 0px;
}

.dropdown-content-scroll {
  max-height: 13rem;
  overflow: auto;
  text-align: start;
}

.block {
  display: block !important;
}

.justify_sb {
  display: flex;
  justify-content: space-between;
}

.flex_sb_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_sb_center > input {
  height: 45px;
  border: none;
  outline: none;
  font-size: 18px;
  color: #7f8e9d;
}

/* / / Chrome, Safari, Edge, Opera / / */
.flex_sb_center > input::-webkit-outer-spin-button,
.flex_sb_center > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / / Firefox / / */
.flex_sb_center > input[type="number"] {
  -moz-appearance: textfield;
}

.flex_sb_center > input:focus {
  color: black;
}

.country-code-div {
  border-right: solid #00000030 1px;
  border-radius: 0px 10px 10px 0px;
  max-width: -moz-max-content;
  max-width: max-content;
  color: #888;
}

.dropdown-icon {
  color: gray;
}

.selected {
  background: rgba(0, 0, 0, 0.08);
  color: #000;
}

#Demoo {
  border-radius: 0px 0px 10px 10px;
  font-size: 20px;
}

.bottom-flat-border {
  border-radius: 10px 10px 0px 0px;
}

.phone-input {
  padding: 0 10px;
}

/* / ///////////////////////// / */

/* / / width / / */
::-webkit-scrollbar {
  width: 8px;
}

/* / / Handle / / */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* / / Handle on hover / / */
::-webkit-scrollbar-thumb:hover {
  background: #00000090;
}

.drop_down_flag_style {
  font-size: 30px;
}

.drop_down_countryName_style {
  font-size: 20px;
  padding: 0px 15px;
}

.hostTakeBoxTexts {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.hostTakeBoxTexts .editTakeBox {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.hostOutside {
  width: 100%;
  border: 1px solid #c7cad9;
  border-radius: 12px;
  margin-bottom: 15px;
}

.hostTakeBox {
  width: 100%;
  border-radius: 12px;
  display: flex;
}
.hostTakeBox.notHost {
  flex-direction: column;
}
.hostTakeBox .hostTakeTopBox {
  padding: 15px 15px 5px 15px;
  display: flex;
  color: #1e3354;
}
.hostTakeBox .hostTakeTopBox .profileImage {
  margin-right: 5px;
}
.hostTakeBox .hostTakeTopBox .profileImage img {
  width: 36px;
  height: 30px;
}
.hostTakeBox .hostTakeTopBox .hostName {
  font-size: 14px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  color: #acacac;
}
.hostTakeBox .hostTakeTopBox .hostName:hover {
  cursor: pointer;
}
.hostTakeBox .hostTakeTopBox .hostName span {
  font-weight: 500;
  color: #1e3354;
}
.hostTakeBox .hostTakeTopBox .hostName span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.hostTakeBox .hostTakeTopBox .hostName img {
  width: 22px;
  height: 22px;
}
.hostTakeBox .hostTakeTopBox .genderAndAge {
  display: flex;
  color: #acacac;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
}
.hostTakeBox .hostTakeTopBox .customHostBox {
  font-weight: 500;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 8px;
  font-size: 14px;
}
.hostTakeBox .middleBox {
  min-height: 60px;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #acacac;
  word-break: break-word;
}
.hostTakeBox .resourcesBox {
  padding: 15px 0px;
  border-top: 1px solid #acacac;
}
.hostTakeBox .resourcesBox.notHost {
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  display: flex;
  align-items: center;
  border-top: none;
  border-left: 1px solid #acacac;
}
.hostTakeBox .resourcesBox .hostResources {
  width: 100%;
  margin-left: 0px !important;
  align-items: center;
}
.hostTakeBox .resourcesBox .hostResources .resource {
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 3px 15px;
}
.hostTakeBox .resourcesBox .hostResources .resource .url {
  font-size: 14px;
  width: 80%;
  display: flex;
  align-items: center;
}
.hostTakeBox .resourcesBox .hostResources .resource .url:hover {
  cursor: pointer;
  text-decoration: underline;
}
.hostTakeBox .resourcesBox .hostResources .resource .buttons {
  min-width: 100px;
  width: -moz-max-content;
  width: max-content;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.hostTakeBox .resourcesBox .hostResources .resource .buttons .customLike {
  border-radius: 18px;
  padding: 3px 10px;
  border: 1px solid #acacac;
}
.hostTakeBox .resourcesBox .hostResources .resource .buttons .reportIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: red;
  border-radius: 100%;
  padding: 3px;
  border: 1px solid red;
}

.userAcceptReject {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px;
  border-top: 1px solid #acacac;
}

@media only screen and (max-width: 767px) {
  .hostTakeBox {
    flex-direction: column;
  }
  .hostTakeBox .resourcesBox.notHost {
    border-left: none;
    border-top: 1px solid #acacac;
  }
}
.editTakeOutside {
  width: 100%;
  border: 1px solid #c7cad9;
  border-radius: 12px;
  padding: 25px;
}
.editTakeOutside .divColum {
  width: 100%;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  display: flex;
}
.editTakeOutside .divColum .headerText {
  width: 100%;
}
.editTakeOutside .divColum .textArea {
  width: 100%;
}
.editTakeOutside .divColum .resourcesOutside {
  width: 100%;
}
.editTakeOutside .divColum .resourcesOutside .resourcesTop {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.editTakeOutside .divColum .resourcesOutside .resourcesTop img {
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
.editTakeOutside .divColum .resourcesOutside .errorBox {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  font-weight: 400;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer {
  width: 100%;
  margin: 10px 0px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer .resourceText {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 80%;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer .buttons {
  display: flex;
  font-size: 20px;
  justify-content: flex-end;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer .buttons .clearIcon {
  cursor: pointer;
  margin-left: 5px;
  border-radius: 100%;
  border: 1px solid red;
  color: red;
  padding: 2px;
  font-size: 20px;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource {
  border: 2px solid #c7cad9;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  display: flex;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource:focus {
  border-color: #ffcb00;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource .textBox {
  width: 100%;
  outline: none;
  border: none;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource .MuiSvgIcon-root {
  cursor: pointer;
  margin-left: 5px;
  width: 22px;
  height: 22px;
}
.editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource img {
  cursor: pointer;
  margin-left: 5px;
  width: 18px;
  height: 18px;
}
.editTakeOutside .divColum .publicAnonBox {
  width: 100%;
}
.editTakeOutside .divColum .bottomText {
  padding: 0px 40px;
  color: #a2a2a2;
  text-align: center;
}
.editTakeOutside .divColum .saveCancelButtonBox .button {
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 12px;
  background-color: #ffcb00;
  color: #1e3354;
  font-weight: 600;
  transition: 0.5s;
}
.editTakeOutside .divColum .saveCancelButtonBox .button.cancel {
  margin-top: 10px;
  background-color: #fff;
  text-decoration: underline;
}
.editTakeOutside .divColum .saveCancelButtonBox .button.cancel:hover {
  background-color: #ededed;
}

.sliderBase {
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 25px;
  background-color: #f9f9f9;
  display: flex;
  font-size: 16px;
  font-weight: 600;
}
.sliderBase .insideButtons {
  width: 50%;
  height: 100%;
  color: #a2a2a2;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.sliderBase .insideButtons.active {
  color: #1e3354;
  cursor: default;
  background-color: #fff;
}

.warningBoxWrapper {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #a2a2a2;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  grid-gap: 10px;
  gap: 10px;
  padding: 15px;
}
.warningBoxWrapper .warningIconWrapper {
  border-radius: 100%;
  border: 2px solid #1e3354;
  color: #1e3354;
  padding: 3px;
}

.smallTextArea {
  height: 100px;
  margin-top: 10px;
}
.smallTextArea .textArea {
  height: 50px !important;
}
.smallTextArea .textArea .MuiOutlinedInput-root {
  border-radius: 12px;
}

@media only screen and (max-width: 767px) {
  .editTakeOutside {
    padding: 10px;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer .resourceText {
    font-size: 14px;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .resourceWrappeer .buttons {
    display: flex;
    font-size: 18px;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource {
    border: 1px solid #c7cad9;
    padding: 10px;
    border-radius: 12px;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource .textBox {
    width: 100%;
    outline: none;
    border: none;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource .MuiSvgIcon-root {
    cursor: pointer;
    margin-left: 5px;
    width: 22px;
    height: 22px;
  }
  .editTakeOutside .divColum .resourcesOutside .resourceList .resources .addNewResource img {
    cursor: pointer;
    margin-left: 5px;
    width: 18px;
    height: 18px;
  }
  .editTakeOutside .divColum .publicAnonBox {
    width: 100%;
  }
  .editTakeOutside .divColum .bottomText {
    font-size: 14px;
    padding: 0px 10px;
    color: #a2a2a2;
    text-align: center;
  }
  .editTakeOutside .divColum .saveCancelButtonBox .button {
    font-size: 16px;
    padding: 10px;
  }
  .editTakeOutside .divColum .saveCancelButtonBox .button.cancel {
    margin-top: 10px;
    background-color: #fff;
    text-decoration: underline;
  }
  .editTakeOutside .divColum .saveCancelButtonBox .button.cancel:hover {
    background-color: #ededed;
  }
  .sliderBase {
    font-size: 13px;
  }
  .warningBoxWrapper {
    font-size: 13px;
  }
  .warningBoxWrapper .warningIconWrapper {
    border-radius: 100%;
    border: 2px solid #1e3354;
    color: #1e3354;
    padding: 3px;
  }
  .smallTextArea {
    height: 80px;
    margin-top: 10px;
  }
  .smallTextArea .textArea {
    height: 30px !important;
  }
  .smallTextArea .textArea .MuiOutlinedInput-multiline {
    padding: 10px;
    font-size: 14px;
  }
  .smallTextArea .textArea .MuiOutlinedInput-root {
    border-radius: 12px;
  }
}
#random-img-container {
  border-radius: 0.5rem;
  cursor: not-allowed;
}

#random-img-container img:hover {
  scale: 1.02;
}

#random-img-container img:active {
  scale: 0.9;
}

#random-img-container img {
  transition: all 0.5s ease;
  background-size: cover;
  border-radius: 0.5rem;
}

.room-times-container {
  overflow-y: auto;
  max-height: 30%;
}

@media (max-width: 1420px) {
  .room-times-container {
    max-height: 27%;
  }
}

@media (max-height: 960px) {
  .room-times-container {
    max-height: 20%;
  }
}

@media screen and (max-width: 767px) {
  #room-times-container {
    border-radius: 0;
  }

  #random-img-container img {
    border-radius: 0;
  }

  #random-img-container img:hover {
    scale: 1;
  }

  #random-img-container img:active {
    scale: 1;
  }
}

html,
body,
#root,
#root>div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.w18 {
  height: 18px;
  width: 18px;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}

.ptb25 {
  padding: 25px 10px;
}

.ptb30 {
  padding: 30px 15px;
}

.time-details {
  padding-top: 50px;
  padding-right: 15px;
}

.room-details-section .MuiContainer-maxWidthLg {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 100px;
}

.divider {
  border: 1px solid #c7cad9;
  height: 100%;
  width: 0px;
  position: relative;
  top: 66px;
}

.wd100 {
  width: 100%;
}

.wd80 {
  width: 80%;
}

.customFlex {
  display: flex;
  justify-content: space-between;
}

.room-details-bg-img {
  background: url(/static/media/Y.d1e3fa63.svg);
  height: 50%;
  width: 50%;
  position: absolute;
  z-index: -1;
  background-size: contain;
  top: 35%;
  left: 10%;
  background-repeat: no-repeat;
}
.room-details .customTypeBox {
  border: 1px solid #c7cad9;
  padding: 1px 7px;
  border-radius: 30px;
  margin-left: 5px;
}

.room-details-bg {
  background: url(/static/media/Y.d1e3fa63.svg);
  height: 100%;
  width: 22%;
  position: absolute;
  z-index: -1;
  background-size: contain;
  top: 10%;
  right: 6%;
  background-repeat: no-repeat;
}

.dFlexJustEnd {
  display: flex;
  justify-content: flex-end;
}

.upcom-img {
  position: relative;
  right: 18px;
}

.back_arrow {
  cursor: pointer;
  transition: all 0.4s ease;
}

.back_arrow:hover {
  scale: 1.2;
}

.rooms-details-calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calen-div {
  background: #f2f3f4;
  padding: 10px 13px;
  border-radius: 6px;
  margin-top: 7px;
}

.room-details-time {
  padding: 12px;
  border-radius: 8px;
  width: 45%;
  margin: 0 15px;
  display: flex;
}

.thumbsup {
  height: 12px;
  width: 15px;
}

.mr5 {
  margin-right: 5px;
}

.upcoming-section {
  padding: 8px 20px !important;
}

.pt20 {
  padding-top: 20px;
}

.mr15 {
  margin-right: 15px;
}

.w28 {
  height: 28px;
  width: 28px;
}

.upcoming-manage {
  flex-direction: column;
  padding: 20px 15px 20px 15px;
}

.standard-details {
  position: relative;
  /* top: 14px; */
}

.room-details-register textarea {
  /* border: 1px solid #c7cad9; */
  outline: none;
  resize: none;
  min-height: 113px !important;
  max-height: 130px !important;
  border-radius: 8px;
  margin-bottom: 15px;
  /* padding: 10px; */
}

.register-textarea {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px !important;
}

.register-textarea .MuiOutlinedInput-multiline {
  padding: 10px;
}

.room-details-register input {
  border: 1px solid #c7cad9;
  outline: none;
  resize: none;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 9px;
}

.add-resources img {
  height: 12px;
  width: 18px;
  margin-right: 5px;
}

.w18 {
  height: 18px;
  width: 18px;
}

.w24 {
  height: 24px;
  width: 24px;
}

.bTop {
  border-top: 1px solid #c7cad9;
}

.text-capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {}

@media only screen and (max-width: 1199px) and (min-width: 992px) {}

/* Register Modal CSS  */
.register-room {
  position: absolute;
  top: 70px;
  padding: 15px;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ffcb00;
  border-radius: 12px;
  width: 450px;
  height: auto;
  right: 15px;
  word-wrap: anywhere;
}

.register-room>div {
  overflow-y: auto;
  max-height: 90vh;
  padding-right: 0.5rem;
}

.part-resources {
  border: 1px solid #c7cad9;
  padding: 10px;
  border-radius: 8px;
}

/* Register Modal CSS  */

.borderYellow {
  border: 1px solid #ffcb00;
}

.p60 {
  padding: 60px 120px;
}

.h_300 {
  /* height: 300px; */
  /* width: 100%; */
}

.wb {
  word-break: break-all;
  white-space: pre-line;
}

.edit-box {
  width: 100%;
  padding: 0.5rem;
  min-height: 4rem;
  border-color: #ccc;
  border-radius: 0.5rem;
  outline: none;
}

.edit-box:hover {
  background: #ececec91;
}

.edit-box:active {
  background: #ececec91;
}

.edit-buttons button {
  border-radius: 0.3rem !important;
}

.edit-buttons button:last-child {
  background: #1e3354;
  color: #fff;
  margin-left: 0.5rem;
  width: 10rem;
}

.dotted-link-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.edit-resource-input input {
  padding: 5px 14px;
}

.back_arrow_mobile {
  display: none;
}

.backAndRoomDetail {
  margin-bottom: 10px;
}

.editUrlIconClass {
  top: 5px;
  right: 10px;
  position: absolute;
  height: 18px;
  font-size: 18px !important;
  color: rgb(174, 173, 173);
  cursor: pointer;
  z-index: 1
}

.customHostBox {
  margin-left: auto;
  margin-right: 0px;
  border-radius: 25px;
  padding: 2px 9px;
  background-color: #ffcb00;
}

.hostResources {
  margin-left: 1rem !important;
  position: relative;
  min-height: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {

  .room-details-section .MuiContainer-maxWidthLg {
    max-width: 1750px;
    padding-top: 10px;
    padding-left:5px;
    padding-right: 5px;
  }

  /* .hostResources {
    padding-top: 40px;
    margin-left: 0px !important;
    min-height: auto;
  } */

  .back_arrow {
    display: none;
  }

  .yourTakeAndResources {
    flex-wrap: wrap;
  }

  .yourTakeAndResources div:first-child {
    border: none !important;
  }

  .backAndRoomDetail {
    margin-top: 60px !important;
    display: flex;
    align-items: center;
    /* margin-bottom: 10px !important; */
  }

  .backAndRoomDetail h2 {
    margin-bottom: 0px !important;
  }

  .back_arrow_mobile {
    display: block;
  }

  .flexWrap {
    flex-wrap: wrap;
  }

  .register-room {
    border: none;
    width: auto;
    right: 0;
    height: 100%;
  }

  .register-room::-webkit-scrollbar,
  .register-room>div::-webkit-scrollbar {
    display: none;
  }

  .upcoming-section {
    /* padding: 0 !important; */
    padding:0px 20px !important;
  }
}

.UpcomingCustomFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.UpcomingCustomBottomFlex {
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: flex-end;
  justify-content: space-around;
}

.roomTypeName {
  font-size: 15px;
  font-weight: 500;
  color: #1e3354;
  padding:1px 7px;
  border-radius: 25px;
  border:1px solid #c7cad9;
}
.coinLabel {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    flex-basis: 48% !important;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-bottom: 3px solid #eaeaea;
}

.coinPriceOutsideBox {
    border: 3px solid #eaeaea;
    border-radius: 10px;
    padding: 10px;
    min-height: 300px;
    justify-content: space-around;
}

.coinBody {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    padding: 0px;
}

.coinDataSpan {
    font-size: 18px !important;
    color: #aeaeae;
    font-weight: 600;
    /* padding: 5px; */
    width: 100%;
}

.boldText {
    font-size: 16px;
    font-weight: 700;
    color: #232323;
    margin-bottom: 5px;
}

.coinRefillBox {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
}

.coinRefillBox.active {
    display: block;
    position: fixed;
    z-index: 1200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.coinRefillBoxContent {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    transition: 0.5s;
    border: 1px solid #888;
    border-radius: 12px;
    width: 60% !important;
    max-width: 1000px !important;
    padding: 40px 120px;
    position: relative;
    /* height: 500px; */
}

.coinRefillBoxContent svg {
    position: absolute;
    top: 20px;
    right: 20px;
}


.paymentMethod {
    border-radius: 8px;
    border: 3px solid #eaeaea;
    margin-top: 0px;

}

.paymentMethodName {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 40px;
    border-bottom: #eaeaea 3px solid;
}

.paymentMethod:last-child {
    border-bottom: none !important;
}

.paymentMethod span {
    padding-left: 20px;
}

.payNowButton {
    cursor: pointer;
    margin-top: 5px;
    padding: 16px;
    background-color: #232344;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

.payNowButtonDisabled {
    cursor: pointer;
    margin-top: 5px;
    padding: 16px;
    background-color: #aeaeae;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

.coinEarnBox {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.coinRefillBoxSuccess {
    margin: 5% auto;
    border: 1px solid #888;
    border-radius: 19px;
    width: 36% !important;
    max-width: 750px !important;
    background: linear-gradient(to bottom, #ffeb3b 50%, #fefad8 80%);
}
.coinRefillBoxImg {
    position: relative;
}

.coinRefillBoxImg .ylanesLogo {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
}

.coinRefillBox img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
}

.coinRefillBox .coinRefillBoxSuccessText {
    margin: 20px 50px;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px;
    border-radius: 18px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.coinRefillBoxSuccessText span {
    padding: 20px 0px;
    display: block;
    width: 100%;
}

.coinRefillBoxSuccessTextBold {
    width: 100%;
    font-weight: 700;
    font-size: 21px;
}

.coinRefillBoxSuccessReferFriend {
    width: 100%;
    margin: 20px 0px;
    font-size: 22px;
    text-align: center;
    color: #232344
}

.coinRefillBoxSuccessContinue {
    width: 100%;
    margin: 50px 50px;
    margin-top: 0px;
    padding: 16px;
    background-color: #232344;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

@media only screen and (max-width: 900px) {

    .coinRefillBoxContent {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 20px;
        border: 1px solid #888;
        border-radius: 12px;
        width: 95% !important;
        max-width: 1000px !important;
        padding: 40px 20px;
        /* height: 500px; */
    }

    .coinRefillBoxSuccess {
        overflow: auto;
        width: 100% !important;
        margin: 0px;
    }

    .coinRefillBox .coinRefillBoxSuccessText {
        margin: 10px;
    }

    .coinRefillBoxSuccessContinue {
        margin: 20px 10px;
    }

    .coinEarnBox {
        font-size: 16px;
        font-weight: 600;
    }
}
.backgroundImage {
  background-image: url(/static/media/Y.d1e3fa63.svg), url(/static/media/Y.d1e3fa63.svg);
}

.rulesOfEngagement {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.rulesOfEngagement.active {
  display: block;
  position: fixed;
  z-index: 1200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.rulesOfEngagement .rulesOfEngagementContent {
  background-color: #fefefe;
  margin: 3% auto;
  flex-direction: column;
  align-items: center;
  border: 1px solid #888;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 50% !important;
  max-width: 1000px !important;
  background-size: 350px, 350px;
  background-position: left -50px top -30px, right -50px bottom -50px;
  background-repeat: no-repeat;
  padding: 50px 120px;
  /* height: 500px; */
}
.rulesOfEngagement .rulesOfEngagementContent .label {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 500;
}
.rulesOfEngagement .rulesOfEngagementContent .smallLabel {
  width: 60%;
  color: #232344;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.rulesOfEngagement .rulesOfEngagementContent .closeButton {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  border: 2px solid #323232;
  border-radius: 100%;
  padding: 18px;
}
.rulesOfEngagement .rulesOfEngagementContent .closeButton img {
  width: 15px;
  height: 15px;
}
.rulesOfEngagement .rulesOfEngagementContent .list {
  width: 60%;
  font-size: 18px;
  font-weight: 500;
}
.rulesOfEngagement .rulesOfEngagementContent .list .col {
  margin-bottom: 25px;
  color: #888;
}
.rulesOfEngagement .rulesOfEngagementContent .list .col:nth-child(n+5) {
  margin-bottom: 0px;
}
.rulesOfEngagement .rulesOfEngagementContent .list .col .number {
  margin-right: 10px;
}
.rulesOfEngagement .rulesOfEngagementContent .list .col .blackText {
  color: #222222;
}
.rulesOfEngagement .rulesOfEngagementContent .list .col .whiteText.list {
  margin-top: 10px;
  list-style: disc !important;
  font-size: 16px;
  margin-left: 20px;
  display: list-item !important;
  width: 100%;
  margin-bottom: 5px;
}
.rulesOfEngagement .rulesOfEngagementContent .checkboxBox {
  width: 60%;
  margin-top: 10px;
  font-size: 18px;
  align-items: center;
  font-weight: 500;
  color: #888;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.rulesOfEngagement .rulesOfEngagementContent .checkboxBox input {
  margin-right: 10px;
}
.rulesOfEngagement .rulesOfEngagementContent .errorBox {
  margin-bottom: 45px;
  height: 20px;
  width: 60%;
  color: red;
  font-size: 16px;
}
.rulesOfEngagement .rulesOfEngagementContent .enterButton {
  cursor: pointer;
  width: 60%;
  margin-top: 5px;
  padding: 12px;
  border: none;
  background-color: #232344;
  color: #fff;
  font-size: 20px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 600;
}

.title {
  color: #28272a !important;
  font-size: 2rem;
  margin-bottom: 2.5rem;
  font-weight: 500;
}

.img-container img {
  max-height: 22rem;
  max-width: 22rem;
  margin-bottom: 4rem;
}

.message {
  color: #9e9cab;
  font-weight: 600;
  font-size: 1rem;
}

.back-btn {
  background: #010039;
  color: #fff;
  width: 65%;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 2rem;
  border: none;
  outline: none;
  padding: 0.7rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-btn:hover {
  box-shadow: 0 5px 10px rgba(1, 0, 57, 0.3);
}

.center {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media only screen and (max-width: 900px) {
  .rulesOfEngagement.active {
    z-index: 9999;
    overflow: auto;
    height: 100vh;
  }
  .rulesOfEngagement .rulesOfEngagementContent {
    margin: 0px;
    padding: 40px 20px !important;
    border-radius: 0px;
  }
  .rulesOfEngagement .rulesOfEngagementContent .closeButton {
    top: 20px;
    right: 20px;
    padding: 6px;
  }
  .rulesOfEngagement .rulesOfEngagementContent .smallLabel {
    width: 100%;
  }
  .rulesOfEngagement .rulesOfEngagementContent .list {
    width: 100%;
  }
  .rulesOfEngagement .rulesOfEngagementContent .enterButton {
    width: 100%;
  }
  .rulesOfEngagement .rulesOfEngagementContent .checkboxBox {
    width: 100%;
  }
}
.dateSelectedClass {
  background-color: #ffcb00 !important;
  color: #fff !important;
}

.create_room_popup .dropdown-content {
  font-size: 14px;
}

.create_room_popup > div:nth-child(1) {
  height: calc(100vh - 84px);
  overflow-x: scroll;
  border: 1px solid #ccccc9;
}


.your-take
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  border: solid 1px #5555;
}
.create_room_popup .Mui-checked .MuiSwitch-thumb {
  background-color: #fff !important;
  border: 1px solid #ccc;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
}

.create_room_popup .MuiSwitch-thumb {
  background-color: grey !important;
}

.refill-wallet {
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #101038;
  text-decoration: underline;
  cursor: pointer;
  padding: 20px;
}

.time-picker .time-picker-ul {
  margin: 1px;
  padding: 0px;
  list-style-type: none;
}

.time-picker .time-picker-ul li {
  padding: 3px 10px;
}

.room-details-time span.last-word {
  background: white;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .create_room_popup > div:nth-child(1) {
    overflow-x: hidden;
    border: none;
  }

  .create_room_popup::-webkit-scrollbar,
  .create_room_popup div::-webkit-scrollbar {
    display: none;
  }
}

.colorYellow {
  color: #ffcb00;
}

.colorGrey {
  color: #7f8e9d;
}

.colorBlack {
  color: #27272a;
}

.colorBlue {
  color: #1e3354;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fBold {
  font-weight: bold;
}

.colorWhite {
  color: #fff;
}

.colorYellow {
  color: #ffcb00;
}

.colorGrey {
  color: #7f8e9d;
}

.colorBlack {
  color: #27272a;
}

.colorLightGrey {
  color: #7f8e9d8a;
}

.bgGrey {
  background-color: #e1dfdf45;
}

.bgWhite {
  background-color: #fff;
}

.bgRed {
  background-color: red;
}

.bgYellow {
  background-color: #ffcb00;
}

.bgDarkGrey {
  background: #ececec91;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fBold {
  font-weight: bold;
}

.shadow {
  box-shadow: 0 0 6px #dddee1;
}

.accord {
  height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (min-width: 1280px) {
  .categories-container {
    padding-right: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .todayImage {
    max-width: 375px;
  }
}

.categories-aside {
  border: 1px solid #c7cad985;
  border-radius: 20px;
}

.categories-head {
  padding: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c7cad9;
}

.daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p15 {
  padding: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt0 {
  margin-top: 0px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.bBottom {
  border-bottom: 1px solid #c7cad9;
}

.bor-bottom-myrooms {
  position: relative;
  border-bottom: 2px solid rgb(225 225 225 / 65%);
  z-index: -1;
  top: -2px;
}

.btn-groups {
  /* padding: 0 15px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.btn-groups span {
  padding: 5px 10px;
  border-radius: 8px;
}

.rooms-accordion {
  padding: 0 15px;
}

.room-times {
  padding-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #c7cad9;
  border-radius: 15px;
}

.room-times h4 {
  padding: 0 15px 15px 15px;
}

.data {
  padding: 15px;
  /* border-bottom: 1px solid #C7CAD9; */
}

.bBottomYellow {
  border-bottom: 2px solid #ffcb00;
}

.pointer {
  cursor: pointer;
}

.sync-calendar img {
  height: 20px;
  width: 20px;
}

.d-flex {
  display: flex;
}

.dFlexAlignCenter {
  display: flex;
  align-items: center;
}

.room-details {
  /* padding: 12px; */
  border-radius: 8px;
  border: 1px solid #c7cad9;
}

.blueCard {
  background-color: #f1f2f8;
}

/* .room-details:hover {
  background: #ececec91;
} */

.w18 {
  height: 18px;
  width: 18px;
}

.w20 {
  height: 20px;
  width: 20px;
}

.w22 {
  height: 22px;
  width: 22px;
}

.w32 {
  height: 32px;
  width: 32px;
}

.w48 {
  height: 48px;
  width: 48px;
}

.borderRight {
  border-right: 1px solid #c7cad9;
}

.pr10 {
  padding-right: 10px;
}

.m15 {
  margin: 15px;
}

.mr10 {
  margin-right: 10px;
}

.ml15 {
  margin-left: 15px;
}

.radius100 {
  border-radius: 100%;
}

.see-more {
  text-align: end;
  text-decoration: underline;
  padding-bottom: 30px;
  border-bottom: 1px solid #c7cad9;
  margin-bottom: 20px;
}

.feedback p {
  width: 99%;
  outline: none;
  border: 1px solid rgb(219 203 203 / 60%);
  padding: 10px 5px;
  border-radius: 4px;
  background-color: #fff;
  word-break: break-all;
}

.see-feedback {
  text-decoration: underline;
}

.border {
  border: 1px solid #c7cad9;
}

.room-types {
  border: 1px solid #c7cad9;
  border-radius: 20px;
  padding: 15px 0;
  margin-bottom: 30px;
}

.ptb15 {
  padding: 15px 0;
}

.plr15 {
  padding: 0 15px;
}

.room-head {
  padding: 0 15px 10px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c7cad9;
}

.room-head:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.radius4 {
  border-radius: 4px;
}

.radius8 {
  border-radius: 8px;
}

.radius15 {
  border-radius: 15px;
}

.w-100 {
  width: 100%;
}

.animate {
  animation: fadeInAnimation ease-out 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Header CSS  */

.appbar-credit {
  font-size: 14px;
  margin-left: 4px;
}

.contained-btn {
  background: #ffcb00 !important;
}

.cover {
  -o-object-fit: cover;
     object-fit: cover;
}

/* Input checkbox CSS  */

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  height: 18px;
  width: 18px;
  border: 2px solid #0000008f;
  border-radius: 7px;
}

input[type="checkbox"]:checked {
  background: #ffcb00;
  border-color: #ffcb00;
}

input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 39%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

/* Input checkbox CSS  */

/* Modal Css  */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  /* min-height: 10%;
  max-width: 24%; */
  height: auto;
  max-width: 800px;
}

/* Modal Css  */

.text-center {
  text-align: center;
  cursor: pointer;
}

.btn-transparent {
  padding: 7px 60px;
  font-size: 16px;
  background: #fff;
  outline: none;
  border: 1px solid #c7cad9;
  border-radius: 4px;
}

.btn-blue {
  padding: 7px 60px;
  font-size: 16px;
  background: #282842;
  outline: none;
  border: 1px solid #282842;
  border-radius: 4px;
  color: #fff;
}

.textDecorationNone {
  text-decoration: none;
}

/* Switch button Css  */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #c9cdd0;
}

.slider.round {
  border-radius: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #c9cdd0;
  transition: 0.4s;
}

input:checked + .slider::before {
  transform: translateX(26px);
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: -15px;
  bottom: -3px;
  background-color: #010138;
  transition: 0.4s;
}

.slider.round::before {
  border-radius: 50%;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0px;
  bottom: -3px;
  background-color: #698091;
  transition: 0.4s;
}

.slider {
  cursor: pointer;
}

/* Switch button Css  */

/* Accordion CSS  */

.accord.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.accord.MuiCollapse-root .accord.MuiCollapse-entered {
  display: none;
}

.accord.MuiCollapse-root .accord.MuiCollapse-entered {
  display: none;
}

/* .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
} */

.MuiFormHelperText-contained {
  text-align: right !important;
}

.accord .MuiAccordion-root .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

/* .MuiSvgIcon-root {
  fill: #1e3354 !important;
  width: 16px !important;
  height: 16px !important;
} */
.accord .MuiAccordion-root .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
  fill: #1e3354 !important;
  width: 16px !important;
  height: 16px !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.accord .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

#demo {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 1px solid #e5e5e5 !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

.accord #panel1a-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiAccordionDetails-root {
  padding: 8px 16px 4px !important;
}

.accord .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
  background: #fffae6;
}

.accord .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

/* Accordion CSS  */
.ptblr {
  padding: 0 15px 10px 15px;
}

.room-times .bBottom:last-child {
  border-bottom: none;
}

/* Scrollbar CSS  */
.scrollbarY {
  height: 90vh;
  overflow-y: auto;
}

.scrollbarYNew {
  height: calc(100vh - 82px);
  overflow-y: auto;
}

/* Scrollbar CSS  */

.pt0 {
  padding-top: 0 !important;
}

.action-status {
  padding: 15px 60px 15px 15px;
}

.seats-time {
  display: flex;
  align-items: flex-end;
  height: 140px;
}

.filters-chip {
  flex-wrap: wrap;
}

.width45 {
  width: 45%;
}

.hour-glass {
  height: 400px;
  width: 400px;
}

.mh75 {
  min-height: 75px;
}

.textUnderline {
  text-decoration: underline;
}

/* Room Tab CSS  */
.roomTab.active {
  border-bottom: 2px solid #ffcb00;
}

/* Loader CSS  */
.loader {
  position: absolute;
  top: 30%;
  right: 50%;
}

.upcomingScrollbar {
  height: calc(100vh - 82px);
  overflow-y: auto;
  -webkit-scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.upcomingScrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

.daf .MuiTypography-body1 {
  font-weight: 500;
}

.pr {
  position: relative;
}

.seeMoreRoomButton {
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0px;
  margin-left: auto;
  text-align: right;
  color: #010138;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}

.seeMoreRoomButton:hover {
  text-decoration: underline;
  color: #27272a;
}

.cancelledBtn {
  display: block;
  text-align: end;
  padding: 10px 40px;
  text-transform: uppercase;
}

.draggable-box {
  transition: transform 0.3s ease-out;
}

.DialogWithoutMargin .MuiDialog-paper {
  margin: 0 0.4rem;
}

.DialogWithoZeroMargin .MuiDialog-paper {
  margin: 0;
}

.NoRoomClassBox {
  margin-top: 50px;
  margin-bottom: 50px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NoRoomClassBox span {
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
  color: #0f0f0f;
}

.NoRoomClassBox img {
  width: 42px;
  height: 42px;
}

.submit-btn-modal {
  padding: 0.8rem 1.2rem;
  border-radius: 2rem;
  background: #ffcc00;
  color: #010039;
  font-weight: 600;
  text-transform: uppercase;

  transition: all 0.3s ease-in-out;
}

.submit-btn-modal:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.submit-btn-modal:active {
  transform: scale(0.95);
}

/* media-query for mobile */
@media screen and (max-width: 767px) {
  .seats-time {
    height: auto;
  }

  .w48 {
    height: 40px;
    width: 40px;
  }

  .upcoming-room_check-mark {
    bottom: 10px;
    height: 18px;
  }

  .action-status {
    padding-right: 10px;
  }

  .accord {
    height: 70vh;
  }

  .accord::-webkit-scrollbar {
    display: none;
  }

  .btn-groups {
    justify-content: space-between;
    padding: 0 1rem;
  }

  .categories-aside {
    border: none;
  }

  .filters-chip {
    padding: 0 0.5rem;
    width: 100dvw;
    overflow: auto;
    flex-wrap: nowrap;
  }

  .filters-chip::-webkit-scrollbar {
    display: none;
  }

  .room-times-container {
    max-height: 43vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .room-times-container::-webkit-scrollbar {
    display: none;
  }

  .room-times {
    border: none;
    margin-bottom: 0;
    padding-top: 0;
  }

  .room-times > h4 {
    padding-top: 15px;
  }

  .room-types {
    margin-bottom: 0;
    padding-top: 0;
  }

  .room-head:first-child {
    padding-top: 15px;
  }

  .seeMoreRoomButton {
    margin-bottom: 1rem;
  }

  .register-room {
    z-index: 1300;
    width: 100dvw;
    top: 7rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  .upcomingScrollbar {
    height: 75dvh;
  }

  .fullHeight .MuiDialog-container {
    height: auto;
    margin-top: 2rem;
  }

  .upcomingRoomsSkeleton:first-child {
    margin-top: 1.5rem;
  }
}

/* media query for extreme smaller screens */
@media screen and (max-height: 680px) {
  .upcomingScrollbar {
    height: 66dvh;
  }
}

.main-feedback-container {
  padding: 15px;
}

.main-feedback-container .header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.main-feedback-container .header div {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  margin-right: 20px;
}

.main-feedback-container .header div h1 {
  font-size: 21px;
  padding: 3px 0px;
}

.main-feedback-container .header img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.main-feedback-container .sub-header {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}

.main-feedback-container .sub-header span {
  color: #cdcdcd;
  font-weight: 500;
  font-size: 18px;
  padding-top: 14px;
}

.main-feedback-container .emoji-container {
  display: flex;
  justify-content: space-around;
  padding: 30px 15px;
}

.rooom-feedback-wrapper {
  margin-top: 15px;
}

.rooom-feedback-wrapper .MuiInputBase-root {
  font-size: 14px !important;
}

.rooom-feedback-wrapper p {
  color: #101038;
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 5px;
}

.drop-down .dropdown-content {
  font-size: 1rem !important;
}

#emoji {
  display: flex;
}

#emoji img {
  width: 40px;
  height: 40px;
  margin: 0 2rem;
  cursor: pointer;
}

#emoji img:hover {
  transform: scale(1.2);
  width: 40px;
  height: 40px;
  margin: 0 2rem;
}

#emoji .applyFilter {
  filter: grayscale(1);
}

.cancel-image-feedback {
  cursor: pointer;
}

.submit-btn-container {
  padding-top: 30px;
}

.submit-btn {
  width: 100%;
  background: #101038 !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.submit-btn:disabled {
  background-color: #dbdae3 !important;
  cursor: not-allowed !important;
}

.your-ratings-container {
  display: flex;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.your-ratings-container p {
  color: #101038;
  font-weight: 500;
}

.feedback-sorry-btn-outter {
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
}

.feedback-sorry-btn-inner {
  background: #fff;
  color: #cdcdcd;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cdcdcd;
  font-weight: 600;
}

.feedback-sorry-btn-inner2 {
  background: #101038;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.feedback-sorry-p-outter {
  margin-top: 20px;
  color: #cdcdcd;
  font-weight: 500;
  font-size: 15px;
}

.ask-refund-text {
  margin-bottom: 0px;
}

.feedback-sorry-span-inner {
  color: #cdcdcd;
  font-weight: 500;
  font-size: 15px;
  padding-top: 15px;
}

.feedback-sorry-span-outter {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}

.feedback-sorry-cancel {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.feedback-sorry-outter {
  padding: 15px;
  width: 400px;
}

.feedback-sorry-inner {
  position: absolute;
  right: 1rem;
}

#error-msg {
  position: fixed;
  z-index: 9999999;
}

.feedback-refund-outter {
  width: 400px;
}

.feedback-refund-outter > div > img {
  width: 400px;
  height: 250px;
}

.refund-message-container {
  background-image: linear-gradient(#ffcb00 0%, #ffffff 100%);
  width: 400px;
  height: 400px;
  padding: 30px;
}

.feedback-refund-span-outter {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  flex-direction: column;
  text-align: center;
  height: 250px;
}

.feedback-refund-span-inner {
  color: #666666;
  font-weight: 500;
  font-size: 15px;
}

.feedback-refund-span-inner-bold {
  font-weight: 600;
  color: #000;
  margin-top: 0.5rem;
}

.feedback-refund-btn-outter {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.feedback-refund-btn-inner {
  width: 90%;
  background: #101038 !important;
  color: #fff !important;
  border-radius: 10px;
  padding: 10px !important;
}

.particiapnt-list > div:not(:last-child) {
  margin-bottom: 1.5rem;
}

.report-list-item {
  cursor: pointer;
  transition: all 0.5s ease;
}

.report-list-item:hover {
  background: #e5e5e5;
}

.mobile-snackbar-styles {
  min-width: 250px;
  margin: auto;
  text-align: center;
  border-radius: 3.2rem;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: black;
  position: fixed;
  top: 8%;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
  padding: 12px 16px;
  font-size: 15px;
}

:root {
  --size: 6.25rem;
}

#local-screen {
  width: 100%;
  height: auto;
  border-radius: 15px;

  height: calc(6.25 * 6.25rem);

  height: calc(6.25 * var(--size));
  /*for testing use gray*/
  background-color: black;

  overflow: hidden;
  position: relative;
}

#time-dot {
  margin: 0 15px;
}

#time-dot .MuiBadge-dot {
  background-color: #d3d3d3;
}

/****inroom******/
#inroom {
  width: 60%;
  position: relative;
}

#video-divider {
  margin: 20px 0 !important;
  height: 0.5px !important;
}

#your-video-feed {
  position: relative;
  width: 100%;
}

#your-video-feed .participant-list {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  height: -webkit-fill-available;
  z-index: 100;
}

.your-video-tool {
  margin: 15px 0;
}

.your-video-tool .your-video-tool-button {
  padding: 11px !important;
  border-radius: 15px !important;
  margin: 0 10px;
  min-width: 30px;
}

#leave-button {
  background-color: #DD5E4A;
  color: white;
  border-radius: 15px;
  width: 10rem;
  font-size: large;
  padding: 5px 15px !important;
  margin-right: 7px;
}

/***skeleton***/
.skeleton {
  width: 6.25rem !important;
  width: var(--size) !important;
  height: 6.25rem !important;
  height: var(--size) !important;
  border-radius: 8px !important;
}

/*******ParticipantVideoCard********/
#participant-video-item {
  flex-direction: column;
}

#participant-video-item .participant-video-card {
  width: -moz-fit-content;
  width: fit-content;
  border: 4px solid #fff;
  border-radius: 20px !important;
}
#participant-video-feed {
  width: 6.25rem;
  width: var(--size);
  height: 6.25rem;
  height: var(--size);

  /*use gray for testing*/
  background-color: black;
  position: relative;
  border-radius: 20px 0px 20px 20px;

  overflow: hidden;
}

#participant-video-feed video {
  width: 130%;
}

#participant-video-item span {
  background-color: white;
  width: 110px;
  text-align: center;
  border-radius: 6px;
  position: relative;
  bottom: 8px;
  overflow: hidden;
  color: #262556;
  font-weight: 500;
}

/*******dropdown menu**********/
#kebab-menu {
  position: absolute;
  right: -3px;
  top: 0;
  background-color: white;
  border: white;
  border-radius: 4px;
  color: gray;
  font-size: 1rem;
  padding: 2px 4px !important;
}

#simple-menu .MuiList-padding {
  padding: 0;
}

#simple-menu .MuiPaper-rounded {
  border-radius: 8px;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

#simple-menu .MuiMenuItem-root {
  width: 17.9rem;
  padding: 0.8rem !important;
  color: black;
  display: flex;
  align-items: center;
}

#ice-breaker {
  background-color: rgb(255, 250, 221);
  padding: 1rem 1rem;
  border: 5px solid rgb(255, 203, 0);
  border-radius: 18px;
  font-size: 1.2rem;
  color: rgb(45, 46, 95);
  display: inline-block;
  width: 55%;
  font-weight: 500;
  word-break: break-word;
  position: absolute;
  top: 8%;
  right: 24%;
  z-index: 100;
}

/*****menuList********/
#simple-menu .menulist-icon {
  margin-right: 8px;
}

@media only screen and (min-width: 500px) and (max-width: 1101px) {
  #inroom {
    width: 95%;
    position: relative;
  }
  :root {
    --size: 5rem;
  }
}

@media only screen and (max-width: 500px) {
  #inroom {
    width: 95%;
    position: relative;
  }

  :root {
    --size: 4.5rem;
  }

  #leave-button {
    width: 100%;
  }

  .your-video-tool .your-video-tool-button {
    width: 20%;
  }

  #participant-video-item {
    padding-right: 8px;
  }

  #participant-video-item span {
    font-size: 0.8rem;
  }

  #participant-video-item .participant-video-card {
    border: 3px solid #fff;
  }

  .your-video-tool {
    align-items: center;
  }
}

.verification-outer {
  width: 60%;
  border-radius: 15px;
  margin: 60px auto;
  height: -moz-max-content;
  height: max-content;
  text-align: center;
  max-width: 95vh;
}

.cancel-out {
  text-align: right;
  z-index: 1;
}

.cancel-out .cancel-icon {
  margin: 15px !important;
  height: 50px;
  width: 50px;
  font-size: 35px !important;
  cursor: pointer;
}

.otp-container {
  margin: 0px 20%;
}

.center-otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-container p {
  font-size: 15px !important;
  color: #00000052 !important;
  font-weight: 400 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-container input {
  width: 50px;
  margin: 10px 8px;
  text-align: center;
  font-size: 25px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0000006e;
  -webkit-appearance: textfield;
}

.submit-button-div {
  width: 100%;
  margin: 0px auto;
}

.submit-button-div .submit-btn {
  width: 100%;
  color: #fff !important;
  background: #150c44 !important;
  font-size: 15px;
  margin: 15px 0px;
  border-radius: 10px;
  box-shadow: unset;
  text-transform: unset;
}

.setup-profile {
  margin: 0px auto;
  width: 100%;
  display: block !important;
}

.setup-profile .setup-submit {
  width: 50%;
}

.bottom-text .text-small {
  font-size: 16px;
}

.bottom-text .text-big strong {
  /* color:#150c44; */
  font-weight: 500;
}

.MuiAlert-root {
  padding: 6px 35px !important;
}

.MuiAlert-filledSuccess {
  color: #000 !important;
  background-color: #95d17c !important;
}

.MuiPaper-elevation6 {
  box-shadow: none !important;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 75px !important;
}

.MuiAlert-root {
  border-radius: 10px !important;
}

.MuiAlert-filledError {
  background-color: #ff5e60 !important;
}

.padding-24 {
  padding: 24px;
}

.MuiStepLabel-label {
  color: rgb(0 0 0 / 22%) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiOutlinedInput-root .dropdown-value {
  padding: 14px;
  color: #000;
}

.MuiStepConnector-lineHorizontal {
  border-top-style: solid !important;
  border-top-width: 4px !important;
}

.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiPaper-elevation0
  .MuiStepLabel-iconContainer
  .MuiSvgIcon-root {
  font-size: 30px !important;
}

.MuiStepConnector-line {
  display: block !important;
  /* border-color: #150c44 !important; */
}

.verification-inner .card-header span {
  font-size: 29px !important;
  font-weight: 500 !important;
  color: #150c44;
}

.verification-inner .AccountCircleIcon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  padding: 1px;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.verification-inner .card-contain {
  text-align: left;
  color: #150c44;
  font-weight: 500 !important;
}

.verification-inner .MuiOutlinedInput-root {
  position: relative;
  border-radius: 12px !important;
  margin: 5px 0px 5px 0px !important;
}

.blur-placeholder .MuiInputBase-input {
  opacity: 0.5;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffd33a !important;
}

.selected-chip {
  margin-top: 5px;
  border-radius: 10px !important;
  background: #150c44 !important;
  color: #fff !important;
  width: 270px;
}

.verification-outer {
  overflow: hidden;
}

.verification-inner.MuiPaper-elevation5 {
  box-shadow: none !important;
}

.inner-grid .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiPaper-elevation5 {
  border-radius: 20px !important;
}

.small-label {
  font-size: 11px;
  opacity: 0.5;
  color: #000;
}

.card-contain .outer-grid .MuiGrid-root {
  margin-bottom: 10px;
}

.welcome-inner {
  padding: 50px;
  background-color: #fff;
  background-image: url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  /* background-position:  left top, 450px 320px;
    background-size: 40% 40% , 45% 45% !important; */
  background-position: 0px -28px, 658px 481px;
  background-size: 29% 37%, 34% 43% !important;
}

.outer-div {
  background: transparent;
  margin: 0% 16%;
  font-family: 'Roboto', sans-serif;
}

.paragraph {
  font-size: 20px;
  color: #7d7b7b;
}

.submit-btn-welcome {
  width: 50%;
  margin-top: 15px !important;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.heading {
  font-size: 15px;
  font-weight: 500;
}

.welcome-inner .heading h2 {
  font-weight: 500;
  font-size: 29px;
}

.onboard-heading p {
  font-size: 30px;
  font-weight: 500;
  margin: 15px;
  margin-top: 20px;
}

.onboard-heading h3 {
  font-weight: 400;
  font-size: 20px;
  margin-top: 29px;
  color: #010038fa;
}

.onboard-heading .onboard-subheading {
  font-size: 17px !important;
  color: gray !important;
  text-align: justify;
}

.noPadding .MuiStepper-root {
  display: flex;
  padding: 0px !important;
}

.h3-tag {
  font-size: 20px;
  /* margin-bottom: -20px; */
  font-weight: 600;
  margin-bottom: -9px;
}

.earnPoint-para p {
  font-size: 14px;
  color: #aaa4a4;
  font-weight: 500;
}

.submit-btn-onboard {
  width: 100%;
  border-radius: 10px !important;
  margin-top: 30px;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.skip-btn {
  margin: 30px 0px 75px 0px;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 400;
  color: #aaa4a4;
  cursor: pointer;
}

.skip-btn-onboarding {
  margin: 35px 20px 0px 19px;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 400;
  color: #aaa4a4;
  cursor: pointer;
}

/* Onboarding css */
.div-container {
  margin: 0px auto;
  width: 50%;
}

.div-container .textField {
  margin: 9px 0px;
  border-radius: 10px !important;
}

.div-container .textField .MuiInputBase-root {
  height: 50px;
}

.div-container .textField .MuiOutlinedInput-root {
  position: relative;
  border-radius: 10px !important;
}

.dot-icon {
  margin: 20px;
  margin-top: 50px;
}

.inactive-stepper {
  color: #80808070;

  width: 25px !important;
  height: 16px !important;
}

.active-stepper {
  font-size: 12px;
  color: #150c44;
  width: 25px !important;
  height: 16px !important;
}

.bottom-text {
  margin: 15px 0px;
  font-size: 12px;
  color: #808080c7;
  font-weight: 500;
}

.buttons-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.buttons-div .btn-multiple {
  width: -moz-max-content;
  width: max-content;
  border-radius: 10px;
  margin: 10px;
  color: #8e8c8c;
  background: #fff;
  text-transform: initial;
  border: 1px solid #cccbcb;
  box-shadow: none;
}

.buttons-div .btn-multiple.active {
  color: #fff !important;
  background: #150c44 !important;
}

.earnPoint-para .addtext-input .MuiInputBase-root {
  height: 48px !important;
  border-radius: 10px !important;
}

.addtext-input input {
  max-width: 275px;
}

.text-area {
  width: 100%;
  margin-bottom: 30px !important;
  max-height: 146px;
}

.text-area .MuiInputBase-root {
  padding: 10px !important;
  border-radius: 10px !important;
}

.text-area .MuiInputBase-root textarea {
  height: 150px !important;
}

.text-area .MuiFormHelperText-root {
  text-align: right;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 10px !important;
  max-height: 180px !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  border-bottom: 1px solid #aaa;
  color: #aaa;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button
  input
  svg {
  color: #aaa;
}

.MuiList-root .MuiMenu-list .MuiList-padding {
  border-radius: 10px !important;
  max-height: 175px !important;
}

.MuiMenuItem-root {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.height-fill-webkit {
  height: 700px !important;
}

.MuiChip-root.selected-chip.MuiChip-deletable {
  display: flex !important;
  justify-content: space-between !important;
}

.MuiChip-root {
  height: 40px !important;
  margin-bottom: 5px !important;
}

.MuiButton-root {
  padding: 10px 37px !important;
}

.moderatorSuccess{
    /* background-color: red; */
    /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  height: 100% !important;
}
.onboardingSuccessCard{
    background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  z-index: 2;

}
* {
  font-family: 'Poppins', sans-serif;
}

/* .main-box.onboarding-detail {
    background-image: url(/static/media/welcome_background_image.275f2cb9.png) , url(/static/media/welcome_background_image.275f2cb9.png), url(/static/media/welcome_background_image.275f2cb9.png);
    background-repeat: no-repeat;
    background-position: 53px 10px, 10px 1800px, 1150px 716px;
    background-size: 28% 20% , 30% 27%, 37% 20% !important;
} */

/* .main-box {
    display: flex;
    padding-top: 100px;
    background-color: #F6F6F6;
    z-index: -2;
}

@media (max-width:850px) {
    .main-box {
        display: flex;
        padding-top: 100px;
        background-color: #F6F6F6;
        flex-direction: column;
    }
}

@media (max-width:450px) {
    .main-box {
        display: flex;
        padding-top: 100px;
        background-color: #F6F6F6;
        flex-direction: column;
    }
} */

#leftGrid-child {
  padding: 60px 0px 0px 10px;
  width: 100%;
  margin: auto;
}

@media (max-width: 850px) {
  .leftGrid-child {
    /* padding: 60px 0px 0px 10px; */
    width: 100%;
    /* margin: auto; */
    border: 1px solid rgb(28, 22, 22);
  }
}

@media (max-width: 450px) {
  .leftGrid-child {
    /* padding: 60px 0px 0px 10px; */
    width: 100%;
    /* margin: auto; */
    border: 1px solid rgb(28, 22, 22);
  }
}

.leftGrid {
  top: 0px;
  width: 100%;
  left: 0px;
  padding-top: 10px;
  /* border: 1px solid black; */
  height: 100%;
}

@media (max-width: 850px) {
  .leftGrid {
    top: 0px;
    width: 100%;
    left: 0px;
    /* padding-top: 10px; */
    /* border: 1px solid black; */
    height: 100%;
    margin-top: -100px;
  }
}

@media (max-width: 450px) {
  .leftGrid {
    top: 0px;
    width: 100%;
    left: 0px;
    /* padding-top: 10px; */
    /* border: 1px solid black; */
    height: 100%;
    margin-top: -100px;
  }
}

.form-box {
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  /* padding-left: 30px; */
}

@media (max-width: 850px) {
  .form-box {
    text-align: left;
    /* width: 100%; */
    padding-left: 20px;
    /* border: 1px solid red; */
  }
}

@media (max-width: 450px) {
  .form-box {
    text-align: left;
    /* width: 100%; */
    padding-left: 10px;
    /* border: 1px solid red; */
  }
}

#main-heading {
  font-weight: 500;
  font-size: 19px;
  color: #27272a;
}

@media (max-width: 850px) {
  #main-heading {
    font-weight: 600;
    font-size: 18px;
    color: #27272a;
    margin-top: 20px;
  }
}

@media (max-width: 450px) {
  #main-heading {
    font-weight: 600;
    font-size: 18px;
    color: #27272a;
    margin-top: 20px;
  }
}

.right-grid {
  width: 100%;
  /* margin-top: -20px; */
  /* padding-left: 6px; */
}

@media (max-width: 850px) {
  .right-grid {
    /* width: 100%; */
    padding-left: 5px;
  }
}

@media (max-width: 450px) {
  .right-grid {
    /* width: 100%; */
    padding-left: 5px;
  }
}

.add-icon {
  width: 15px;
  /* padding-top: 140px; */
  position: absolute;
}

.add-icon2 {
  width: 15px;
  margin-bottom: 75px;
  position: absolute;
}

/* 
.input-field {
    border: 1px solid gray;
    border-radius: 8px;
    opacity: 0.8;
    width: 100%;
    height: 50px;
    padding-left: 10px;
} */
.add-iconPles {
  width: 15px;
}

.cross-icon {
  width: 12px;
  color: #010039;
  margin-top: 30px;
  margin-left: -3px;
}

.addbuttonAbsolute {
  position: absolute !important;
  left: 97%;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 0px !important;
  width: -moz-fit-content;
  width: fit-content;
  top: -27px;
}

.addbutton {
  position: relative;
  left: 95%;
}

.addbutton:hover {
  background-color: transparent !important;
}

.removeButton {
  height: 32px;
  padding: 0px !important;
}

.removeButton .cross-icon {
  margin: 0px;
}

.fieldGrid {
  margin-top: 16px;
}

.fieldGrid:not(:first-child) {
  margin-top: 40px;
  width: 100%;
  /* margin-left: 2%; */
}

.field-array-input {
  border: 1px solid #bab1b1;
  border-radius: 8px;
  opacity: 0.8;
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  background-color: #ffffff;
  padding-left: 10px;
}

.input-field {
  border: 1px solid #c8cbdb;
  border-radius: 8px;
  /* opacity: 0.8; */
  width: 100%;
  height: 50px;
  padding-left: 10px;
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 100%;
}

select {
  border-right: 16px solid transparent;
}

#field-array-labels {
  padding-bottom: 5px;
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #08063d;
}

.upload-docs {
  border: 1px solid #a3a3b8;
  border-radius: 8px;
  opacity: 0.8;
  width: 100%;
  height: 48px;
  padding: 20px 10px;
  background-color: #ffffff;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

Select {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

.drop-icon {
  width: 13px;
  position: relative;
  left: 86%;
  z-index: 1;
  top: 28px;
}

.drop-icon2 {
  width: 13px;
  position: relative;
  left: 86%;
  z-index: 1;
  /* top: 10px; */
}

accordionRoot {
  height: '15px';
}

.doc-input {
  width: 100%;
}

.upload-doc-btn {
  margin: auto;
}

.drop-icon {
  width: 13px;
  position: relative;
  left: 86%;
  z-index: 1;
  top: 28px;
}

.drop-icon2 {
  width: 13px;
  position: relative;
  left: 86%;
  z-index: 1;
  /* top: 10px; */
}

input[type='file'] {
  width: 100%;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #f5de5a;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.row {
  margin-top: -30px;
}

.blur-placeholder .MuiInputBase-input {
  opacity: 0.5;
}

.MuiOutlinedInput-root .dropdown-value {
  padding: 14px;
  color: #000;
  width: 100%;
}

/* input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  } */

.year-select {
  width: 100%;
}

.upload-file-adtn-moderator {
  display: flex;
  flex-direction: row;
}
.upload-file-adtn-moderator span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 450px) {
  .year-select {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .year-select {
    width: 100%;
  }
}

.dashed-line {
  border: 1px dashed rgb(106, 102, 102);
}

.div {
  display: flex;
  flex-direction: column;
}

.secondTick {
  width: 20px;
}

.dashed-image {
  background-image: linear-gradient(rgb(219, 213, 213) 65%,
      rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 194px;
  margin-left: 10px;
}

.dashed-image0 {
  background-image: linear-gradient(#010039 65%, rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 217px;
  margin-left: 10px;
}

.dashed-image1 {
  background-image: linear-gradient(rgb(219, 213, 213) 65%,
      rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 855px;
  margin-left: 10px;
}

.dashed-image10 {
  background-image: linear-gradient(#010039 65%, rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 855px;
  margin-left: 10px;
}

.dashed-image2 {
  background-image: linear-gradient(rgb(219, 213, 213) 65%,
      rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 235px;
  margin-left: 10px;
}

.dashed-image20 {
  background-image: linear-gradient(#010039 65%, rgba(255, 255, 255, 0) 0%);
  background-size: 1px 26px;
  min-width: 1px;
  max-width: 1px;
  height: 235px;
  margin-left: 10px;
}

.form-onboardingDetails select {
  font-size: 16px !important;
  background: #fff !important;
}

.form-onboardingDetails input {
  font-size: 16px !important;
  background: #fff !important;
}

.form-onboardingDetails textarea {
  font-size: 16px !important;
  background: #fff !important;
}

.form-onboardingDetails .MuiSelect-select {
  font-size: 16px !important;
  background: #fff !important;
}

.onboarding-detail-accordion .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

/* .onboarding-detail  */
.dropdown-container {
  width: 100%;
}

/* .onboarding-detail  */
.button h6 {
  color: #fff !important;
}

.modalContainer {
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow: auto;
}

.modalContent {
  border-radius: 12px;
  background-color: white;
  padding: 40px 0px;
  min-width: 700px;
  width: 50%;
  min-width: 370px;
  margin: 40px 0px 0px 0px;
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalContent img {
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 350px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.modalButtons {
  flex-direction: column;
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  width: 60%;
}

.modalImage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.modalImageText {
  position: relative;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.modalImageText span {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modalSaveButton1 {
  width: 100%;
  padding: 15px;
  background-color: #FFCB00;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: .3s;
}

.modalSaveButton1:hover {
  cursor: pointer;
  background-color: #e0b300;
}

.modalCoutunieButton1 {
  width: 100%;
  padding: 15px;
  border: 1px solid #FFCB00;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: .3s;
}

.modalCoutunieButton1:hover {
  cursor: pointer;
  background-color: #e0b300;
}

.modalSaveButton2 {
  width: 100%;
  color: #555;
  text-align: center;
  font-weight: 500;
  padding: 15px;
  transition: .3s;
}

.modalSaveButton2:hover {
  cursor: pointer;
  background-color: #c8cbdb;
  border-radius: 50px;
}
/* .setting-main-div .MuiPaper-root.MuiDrawer-paper.Setting-drawerPaper-6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{
    z-index: 1000;
    margin-top: 65px;
    background-color: #fbfbfb;
  } */
.setting-main-div .MuiPaper-root.MuiDrawer-paper {
  top: 65px !important;
}

.list-item
  .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-weight: 500 !important;
}

.MuiPaper-root.MuiDrawer-paper.Setting-drawerPaper-6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  border-right: none;
}

.MuiDivider-root {
  height: 0px;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  box-shadow: none !important;
}

.MuiIconButton-root {
  color: #c7cad9 !important;
}

.MuiButtonBase-root.MuiListItem-root.Setting-selected-8:hover {
  background-color: #010138 !important;
  color: #fff !important;
}

.container-c1d {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 1rem;
  align-items: center;
  padding: 0px 10px 0px 10px;
  background-color: #fff;
}

.container-c1d h3 {
  font-weight: 600;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #010138 !important;
}

.MuiSwitch-thumb {
  background-color: #010138 !important;
}

.feedback-container {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}

.plus-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.PrivateNotchedOutline-root-37.MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
}

.MuiOutlinedInput-root {
  border-radius: 10px;
}
.grey-para {
  color: #00000050;
  font-weight: 500;
}

.MuiSvgIcon-root.dropdown-icon {
  margin-right: 10px;
}

.textField {
  height: 30px;
}

.textarea-custom {
  height: 300px;
}
.textarea-custom .text-area .MuiInputBase-root textarea {
  height: 250px !important;
}

.delete-btn {
  background-color: transparent !important;
  color: #e70a0a !important;
  border: 1px solid #e70a0a !important;
  margin-top: 15px !important;
  box-shadow: none !important;
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.delete-btn:disabled {
  background-color: #ccc !important;
  pointer-events: none;
}

.deactivation-dropdown .dropdown-content {
  color: #7f8e9d !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.deactivation-dropdown .dropdown-content-scroll {
  max-height: 165px;
}
.terms-codtn-para {
  color: #979797;
  font-weight: 400;
}

/* modal  */

.alert-modal .MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
}
.alert-modal .MuiButton-root.modal-cancel-btn {
  width: 250px;
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 16px;
  color: #010138;
}
.alert-modal .MuiButton-root.modal-delete-btn {
  width: 250px;
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 16px;
  background-color: #e70a0a;
  border: 1px solid #e70a0a;
  color: #fff;
}

.MuiPaper-rounded {
  border-radius: 10px;
}

.margin-bottom-custom {
  margin-bottom: 10px !important;
}

.list-item-settings .Setting-selected-20.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #010138 !important;
  color: #fff !important;
}

.list-item-settings .Setting-selected-21.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #010138 !important;
  color: #fff !important;
}

.otp-input-container > div {
  flex: 0.5 1;
}

@media screen and (max-width: 768px) {
  .feedback-container {
    width: 100%;
  }
}

/* * {
  padding: 0;
  margin: 0;
  font-family: "Baloo Bhai 2", cursive;
  overflow-x: hidden;
} */
.moderator-wrapperr {
  /* background-image: url(/static/media/welcome_background_image.275f2cb9.png) , url(/static/media/welcome_background_image.275f2cb9.png), url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  background-position: 0px -28px, 170px 1599px, 1350px 768px;
  background-size: 29% 37% , 26% 19%, 27% 31% !important */
}
.moderator-wrapperr .categories-aside {
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  background: #fff
}

.moderator-wrapperr .sidebar{
  box-sizing: border-box !important;
  max-height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
  overflow-x: auto !important;
  padding: 0px !important;
  margin-top: 12px;
  position: sticky;
  top: 66px; 
}

.background-image1-y-inner{
  width: 470px;
  position: absolute;
  top : -80px;
  left : 80px;
  z-index: 0;
}
.background-image2-y-inner{
  width: 470px;
  position: absolute;
  right: 0px;
  top:550px;
}
.background-image3-y-inner{
  width: 470px;
  position: absolute;
  left: 100px;
  top:1350px
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: lightgrey !important;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background:  #0000008f;
  border-radius: 5px;
}

.moderator-wrapperr .categories-head {
  padding: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7cad9;
  font-size: 18px;
}

.moderator-wrapperr div.buttons-div-moderator{
  padding-bottom: 15px !important;
}

.moderator-wrapperr .buttons-div-moderator.bBottom .MuiButton-contained{
  height: 30px !important;
}

.moderator-wrapperr .categories-head * {
  margin: 0px;
  
}

.moderator-wrapperr .daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p15 {
  padding: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

/* .mb20 {
  overflow:hidden
}
.mb30 {
  margin-bottom: 30px;
} */

/* .moderator-wrapperr .bBottom {
  border-bottom: 1px solid rgb(192, 192, 192);
} */

.moderator-wrapperr .data {
  padding: 15px;
  /* border-bottom: 1px solid rgb(192, 192, 192); */
}
.moderator-wrapperr .daf.bBottom:last-child{
  border-bottom: none !important;
}

.moderator-wrapperr .daf.bBottom:first-child{
  border-top: 1px solid #c7cad9;
}

.selected-category{
  background : #fffae6
}

 .flex-sb-center{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

 .flex-sb{
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
/* .bBottomYellow {
  border-bottom: 2px solid #ffcb00;
} */

.pointer {
  cursor: pointer;
}


.moderator-wrapperr .buttons-div-moderator{
  display: flex;
  justify-content: space-between;
  padding: 5px 10px !important;
}

.moderator-wrapperr .btn-Category-inner {
  box-shadow: none !important;
  background: #ffcd2f !important;

}

.moderator-wrapperr .btn-unselected {
  box-shadow: none !important;
  background: none !important;

}

.moderator-wrapperr .btn-age {
  width: 100%;
  justify-content: start !important;
  padding-left: 20px;
  /* border-bottom: 1px solid #E5E5E5; */
}


.moderator-wrapperr .btn-age.MuiButton-root {
  border-radius: 0px !important;
}

.moderator-wrapperr .btn-age-outter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    margin-bottom: 1px;
    border-top: 1px solid #E5E5E5;
}
.moderator-wrapperr .btn-age{
  padding: 10px 15px !important;
}

.moderator-wrapperr .heading-moderator{
  font-size: 25px;
    font-weight: 600;
    margin-top: 0px;
    margin-left: 15px;
}

.moderator-wrapperr .list-item-outter{
  border : 1px solid #E5E5E5;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
}
.moderator-wrapperr .list-first-div{
  margin-top:5px
}

.moderator-wrapperr .list-first-div-inside{
  color: #444461;
  font-weight: 600;
  padding-right: 10px;
  font-size: 16px;
}

.moderator-wrapperr .list-first-div .span-after-username{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00000050 !important;
}

.moderator-wrapperr .list-first-div > span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #010138;
}

.moderator-wrapperr .list-second-div{
  color: #00000050;
}

.moderator-wrapperr .rooms {
  padding: 0px 5px;
}

.moderator-wrapperr .rooms svg{
  font-size: 11px;
  /* padding: 0px 7px 0px 16px; */
  position: relative;
  margin: 0px 5px;
  top: 1px;
  color: #00000030;
}

.moderator-wrapperr .list-item-content p {
  color: #c1c1c1;
  font-weight: 500;
  width: 80%;
  padding-top: 10px;
}

.moderator-wrapperr .list-item-outter button{
  background: #010138; 
  color: #fff;
  border: 1px solid #000;
  border-radius: 30px !important;
  padding: 5px 20px;
}

.moderator-wrapperr .buttons-div-moderator.null{
  background: unset;
  box-shadow: none;
  border-radius:unset ;
}

@media only screen and (max-width: 980px) {

  .moderator-wrapperr {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .moderator-wrapperr .list-first-div-inside{
    display: inline-block;
    width: 100%;
  }
  .moderator-wrapperr .list-first-div-inside span{
    display: inline-block;
    width: 70%;
  }

  .moderator-wrapperr .MuiListItemSecondaryAction-root {
    top: 20px !important;
  }
  .moderator-wrapperr .list-item-outter button{
    padding:5px 16px !important;
    font-size: 14px !important;
  }
  .list-thirth-text {
    word-break: break-word;
  }
  
  .moderator-wrapperr .background-image-y-outter {
    display: none;
  }

  .moderator-wrapperr .list-item-outter {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #E5E5E5;
  }

  .moderator-wrapperr .outer-moderator-list {
    padding: 0px !important;
    padding-bottom: 40px !important;
  }

  .moderator-wrapperr .list-item-content p {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
    width: 100% !important;
  }
}
.appbar-credit {
  font-size: 14px;
  margin-left: 4px;
  color: #000;
  font-weight: 500;
}

.contained-btn {
  background: #ffcb00 !important;
}

.mobile-btn {
  border: none;
  outline: none;
  border-radius: 0.3rem;
  padding: 0.5rem 0.6rem;

  font-size: 0.85rem;
  font-weight: 500;
  color: #000;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.image-logo {
  margin-right: 9%;
}

.margin-spacing-right {
  margin-right: 20px !important;
}

.icon-style {
  height: 40px;
  width: 40px;
}

.message-icon-style {
  height: 18px;
  width: 18px;
  right: 22px;
  position: absolute;
}

.bell-icon-style {
  height: 18px;
  width: 18px;
  right: 22px;
  position: absolute;
}

/* css for search */

body {
  font-family: "Open Sans", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  /* list-style: none; */
}

.container {
  display: flex;
  flex-direction: column;

  padding-bottom: 40px;
}

.input_container {
  min-width: 0;

  /* position: relative; */
  /* &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: solid 2px #979797;
    pointer-events: none;
  }
  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    
    pointer-events: none;
  }
  &:focus-within {
    &::after {
      transform: scaleX(1);
    }
  } */
}

.input_container::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* border-bottom: solid 10px red; */
  pointer-events: none;
}

.input_container::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  pointer-events: none;
}

.input_container:focus-within::after {
  transform: scaleX(1);
}

.input {
  width: 100% !important;
  height: 40px !important;
  position: unset !important;
  opacity: 1 !important;
  border: none !important;
  padding: 0px 50px !important;
}

.input:focus-visible {
  outline: none !important;
}

.input::-moz-placeholder {
  color: #b3b3b3;
}

.input::placeholder {
  color: #b3b3b3;
}

.dropdown {
  width: 100%;
  min-width: 0;
  /* height: 100%; */
  position: absolute;
  overflow-x: hidden;
  /* border: 1px solid #b3b3b3; */
  background-color: #fff;
  visibility: hidden;
  z-index: 1301;
  left: 0px;
  top: 30px;
  border-right: 1px solid #aaaaaa50;
  border-left: 1px solid #aaaaaa50;
  border-bottom: 1px solid #aaaaaa50;
  border-radius: 0px 0px 10px 10px;
}

.dropdown.v {
  visibility: visible;
}

.dropdown.v .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.dropdown.v .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: none !important;
  border-radius: none !important;
}

.dropdown_item {
  width: 100%;
  max-width: 407px;
  padding: 12px;
  cursor: pointer;
  /* &:hover {
    background-color: #f1f1f1d7;
  } */
  border-bottom: 1px solid #aaaaaa50;
}

.item_text1 {
  color: #090a3c;
  margin-bottom: 5px;
}

.item_text2 {
  font-size: 14px;
  color: #090a3c80;
}

.search-test {
  border: 1px solid #aaaaaa50;
  border-radius: 10px;
  overflow: hidden;
}

.accordion-list {
  max-height: calc(100vh - 80px);
}

.user-list-outter .list-name {
  max-width: 245px;
  padding-right: 5px;
}

.btn-connected {
  border-radius: 25px !important;
  background: #010138 !important;
  color: #fff !important;
}

/* Header profile drop down CSS */
.popup {
  width: 180px;
  margin: 0 auto;
  position: absolute;
  top: 124%;
  right: 0px;
  z-index: 999;
  border-radius: 15px;
  box-shadow: 1px 3px 24px rgb(0 0 0 / 50%);
}

.arrow {
  width: 22px;
  height: 38px;
  transform: rotate(-47deg);
  box-shadow: 0 1px 14px rgb(0 0 0 / 20%);
  background: #fff;
  position: absolute;
  z-index: 998;
  /* width: 0; */
  /* height: 0; */
  /* border-left: 15px solid #0002; */
  /* border-right: 15px solid #0002; */
  /* border-bottom: 17px solid white; */
  /* border-radius: 100% 100% 0px 0px; */
  top: -13px;
  right: 17px;
  border-radius: 5px;
  background: #fcf0c4;
}

.noP {
  padding: 0px !important;
}

.top-heading-popup {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #010138;
  background: #fcf0c4;
  word-break: break-word;
}

.popup-wrapper p {
  font-size: 18px;
  font-weight: 600;
  color: #010138;
  text-align: left;
  padding: 15px 25px !important;
}

/* .popup:before {
  content: "";
  width: 16px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
  background: #fff;
  position: absolute;
  z-index: 998;
  
} */
/*
Different arrow positioning
*/
/* .popup.arrow-top:before {
  left: calc(50% - -50px);
  top: -8px;
}
.popup.arrow-right:before {
  top: calc(50% - 10px);
  right: -8px;
}
.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
} */

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper {
  width: 100%;
  border-radius: 15px;
  background: #ffffff;
  position: relative;
  z-index: 998;
  text-align: center;
  overflow: hidden;
}

.popup-wrapper p {
  border-bottom: 1px solid #e9e9e9;
  margin: 0px;
}

.notificationDot {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111075;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 10px;
  color: #fff;
  font-size: 70%;
}

.noDataFoundSearch {
  display: flex;
  height: 220px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.noDataFoundSearch img {
  height: 140px;
  width: 140px;
}

.noDataFoundSearch span {
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
}

.notification-card {
  transition: all 0.5s ease;
}

.notification-card:hover {
  background: #ccc;
}
.terms-heading {
    display: flex;
    margin: auto;
    color: #010138;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
}

.terms-content {
    margin: 15px 0px;
    border: 1px solid #0005;
    padding: 20px;
    border-radius: 10px;
}
.boldLabel {
  font-size: 27px;
  font-weight: 500;
  text-align: left;
}

.muiTabs {
  font-weight: 500;
  font-size: 18px !important;
  text-transform: none !important;
  padding: 20px 25px !important;
  color: #222222 !important;
}

.muiTabs.Mui-selected {
  color: #232323 !important;
}

.css-1aquho2-MuiTabs-indicator {
  height: 12px;
  background-color: #ffeb3b !important;
}

.categoryOutsideBox {
  background-color: transparent;
  height: 350px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryOutsideBox .categoryOutsideBox .MuiSkeleton-text {
  transform: scale(1, 1);
}

.categoryBox {
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  height: 90%;
  width: 85%;
  background-color: white;
  border-style: solid;
  border-radius: 12px;
  border-width: 1px 1px 1px 1px;
  border-color: #c7cad9;
}

.categoryImage {
  border-radius: 12px;
  width: 100%;
  height: 220px;
  background-color: #bdbdbd;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  -o-object-fit: cover;
     object-fit: cover;
}

.categoryImage span {
  width: 100%;
  height: 100%;
  background-size: unset;
}

.categoryImage img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.categoryName {
  background-color: #fff;
  height: 90px;
  padding: 10px;
  display: flex;
  border-radius: 0px 0px 12px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

.categoryName span {
  background-color: #fff;
}

.categorySubLabel {
  display: flex;
  height: 60px;
  align-items: flex-end;
  justify-content: left;
  padding-left: 20px;
}

.categorySubLabel span {
  font-size: 20px;
  font-weight: 500;
}

.roomTypesBox {
  background-color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px;
  border: 1px solid #c7cad9;
  border-radius: 15px;
}

.roomTypesLabel {
  display: flex;
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #c7cad9;
  padding: 16px;
  font-size: 22px;
  font-weight: 500;
  justify-content: left;
  align-items: center;
}

.roomTypesCheckbox {
  background-color: transparent;
  padding: 20px;
  display: flex;
  border: 1px solid #c7cad9;
}

.roomTypesCheckbox:nth-last-child(1) {
  border: none !important;
}

.nameIconBox {
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  width: 100%;
}

.nameIconBox span,
.nameIconBox svg {
  margin-left: 15px;
  background-color: #fff;
  font-weight: 500;
}

.checkboxBox {
  background-color: transparent;
}

.tab {
  padding: 0px 10px;
  transition: 0.5s;
  flex-wrap: nowrap !important;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
}

.tab.active {
  transition: 0.5s;
  border-bottom: 3px solid #ffeb3b;
  cursor: none;
}

.subCategoryWrapper .MuiGrid-container {
  justify-content: flex-start;
}

.lazyLoad img {
  width: 50px;
  height: 50px;
}

.NoRoomClassBox {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.NoRoomClassBox .createRoomButtonBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.NoRoomClassBox .createRoomButtonBox .contained-btn {
  padding: 8px 30px !important;
}
.NoRoomClassBox .createRoomButtonBox span {
  font-size: 16px !important;
}

.NoRoomClassBox .secondText {
  text-align: center;
  font-size: 16px;
  color: #bdbdbd;
}

.NoRoomClassBox span {
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
  color: #0F0F0F;
}

.NoRoomClassBox img {
  width: 42px;
  height: 42px;
}

@media only screen and (max-width: 900px) {
  .boldLabel {
    max-width: 275px !important;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }
  .boldLabel span {
    display: none;
  }
  .tabDiv {
    grid-gap: 20px;
    gap: 20px;
    width: 100% !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    overflow-y: none !important;
  }
  .tabDiv::-webkit-scrollbar {
    display: none !important;
  }
  .tab {
    white-space: nowrap !important;
    color: #c7cad9;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }
  .tab.active {
    color: #222222;
  }
  .lazyLoad {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  .tabList {
    grid-gap: 10px;
    gap: 10px;
    max-width: 100% !important;
    width: 100% !important;
    flex-wrap: nowrap !important;
  }
  .tabList.profile {
    border-bottom: 1px solid #bdbdbd;
  }
  .categoryOutsideBox {
    width: 50% !important;
    height: 233px;
    margin-bottom: 10px;
  }
  .categoryOutsideBox .categoryBox {
    cursor: pointer;
    background-color: #fff;
    border-radius: 12px;
    height: 90%;
    width: 85%;
    background-color: white;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px 1px 1px 1px;
    border-color: #c7cad9;
  }
  .categoryOutsideBox .categoryImage {
    width: 100%;
    height: 150px;
  }
  .categoryOutsideBox .categoryName {
    height: 55px;
    padding: 3px;
    font-size: 13px;
  }
  .categoryOutsideBox .categoryName span {
    background-color: #fff;
  }
}
.subcategoriesBox {
  width: 100%;
}

.subcategoriesBackButton {
  font-size: 22px;
  display: flex;
  font-weight: 400;
  cursor: pointer;
  padding-bottom: 15px;
  align-items: center;
  padding-left: 0px;
  text-align: left;
}

.subcategoriesBackButton img {
  margin-right: 17px;
}

.subcategoriesLabel {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.subcategoriesList {
  height: calc(100vh - 220px);
  overflow: auto;
  border: 1px solid #c7cad9;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding-top: 10px;
}

.subcategoriesListBox {
  padding: 13px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
  transition: 0.3s;
}

.subcategoriesListBox.active {
  background-color: #222255;
  color: #fff;
}

.subcategoriesListBox.active .subcategoriesName span {
  color: #fff !important;
}

.subcategoriesName {
  color: #fff;
  display: flex;
  align-items: center;
}

.subcategoriesName span {
  color: #222255;
  margin-left: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.subcategoriesName img {
  width: 40px;
  height: 40px;
  margin-left: 15px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
}

/* SUBCATEGORY*/
.subcategoryBreadcrumb {
  height: 78px;
}
.subcategoryBreadcrumb .MuiSkeleton-text {
  transform: scale(1, 1);
}

.subcategoryBreadcrumb li {
  color: #222222;
}

.subcategoryBreadcrumb p {
  font-size: 20px !important;
}

.subcategoryBreadcrumb a {
  font-size: 20px !important;
  cursor: pointer;
  color: #222222;
}

.topicAndRoomCountLabel {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px 10px 10px 10px;
  font-size: 18px;
  color: #222255;
  font-weight: 600;
  grid-gap: 5px;
  gap: 5px;
}
.topicAndRoomCountLabel span {
  font-size: 16px;
  background-color: ffeb3b;
  min-height: 30px;
  width: -moz-fit-content;
  width: fit-content;
  aspect-ratio: 1/1;
  height: -moz-fit-content;
  height: fit-content;
  padding: 7px;
  min-width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.subcategoryPostOutisde {
  width: 100%;
  padding: 30px 25px;
  height: 180px;
}

.subcategoryName {
  font-weight: 500;
  text-align: left;
  font-size: 24px;
  color: #222255;
}

.subcategoryBannerBox {
  margin-bottom: 20px;
  width: 100%;
  min-height: 360px;
  border-radius: 12px;
  position: relative;
}

.subcategoryBanner,
.subcategoryBanner img {
  /* background-color: #ffeb3b; */
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-radius: 12px;
  height: 100%;
  -o-object-position: top;
     object-position: top;
}

.subcategoryBanner span {
  width: 100%;
  height: 100%;
}

.imgText {
  position: absolute;
  top: 0;
  border-radius: 12px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  padding: 30px;
  height: 500px;
  font-size: 26px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%);
}

.imgText span {
  font-weight: 500;
  font-size: 28px;
}

.roomCount {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #aeaeae;
  height: 50px;
  align-items: flex-end;
}

.subcategoryDesc {
  overflow: hidden;
  height: 70px;
  text-align: left;
  font-weight: 500;
  color: #8e8e8e;
}

.subcategoryButton {
  color: #222255;
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-full {
  width: 100%;
  display: flex;
  max-height: 180px;
  border-radius: 12px !important;
  border: 1px solid #c7cad9;
  /* box-shadow: 0px 0px 30px 1px rgba(100, 100, 100, 0.12); */
}
.d-full .subcategoryForMobile {
  display: none;
}

.marginTop {
  margin-top: 30px;
}

.noRoom {
  width: 100%;
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  color: #8e8e8e;
}

.noRoomDesc {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 600;
  color: #8e8e8e;
}
.noRoomDesc .noShowCrateRoom {
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  text-align: center;
}

.CreateRoomBox {
  justify-content: space-between !important;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #aeaeae;
}

.CreateRoomBox .label {
  font-size: 24px;
  margin-bottom: 10px;
  display: block;
  font-weight: 600;
}

.createRoomButtonBox {
  display: flex;
  justify-content: right;
  align-items: flex-end;
}
.createRoomButtonBox .contained-btn {
  font-size: 14px !important;
}

.loadingOutsideDiv {
  height: 100%;
  display: flex;
  width: 100%;
  min-height: 150px;
  justify-content: center !important;
  align-items: center;
}

/*POST CSS*/
.postInside {
  border: 1px solid #c7cad9;
}

.postDescription {
  padding: 20px;
}

.postDescription .label {
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  color: #222255;
  text-align: left;
}

.postDescription .tags {
  margin-top: 5px;
  width: 100%;
  color: #8e8e8e;
  font-size: 19px;
  font-weight: 500;
  text-align: left;
}

.postDescription .roomType {
  margin-top: 5px;
  width: 100%;
  color: #8e8e8e;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
}

.postDescription .desc {
  margin-top: 10px;
  color: #8e8e8e;
  text-align: left;
  overflow: hidden;
  height: 70px;
  width: 100%;
  font-weight: 500;
}

.postDescription .seats {
  width: 100%;
  margin-top: 40px;
  color: #8e8e8e;
  text-align: left;
  overflow: hidden;
  font-weight: 500;
}

.postDescription .date {
  width: 100%;
  font-weight: 500;
  margin-top: 10px;
  color: #222255;
  text-align: left;
  overflow: hidden;
}

.postParticipacions {
  border: 1px solid #c7cad9;
  padding: 15px;
}

.leftMenuWrapper {
  padding: 0px 20px;
}
.leftMenuWrapper .MuiSkeleton-text {
  transform: scale(1, 1);
}

.postLabel {
  width: 100%;
  display: inline-block;
  font-size: 20px;
  color: #8e8e8e;
  text-align: left;
  font-weight: 600;
}

.postUserBox {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.postUserBox.host img {
  margin-right: 15px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}

.postUserBox img {
  margin-right: 15px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
}

.postUserRight {
  text-align: left;
  width: 100%;
}

.username {
  font-size: 18px;
  font-weight: 500;
  padding-right: 10px;
}

.userage {
  font-size: 17px;
  font-weight: 500;
  color: #8e8e8e;
}

.postPrice {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  color: #222255;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
}

.postPrice span {
  margin-left: 10px;
}

.postRegisterButton {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  color: #222255;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 600;
}

.subCategoryWrapper {
  padding: 20px;
  padding-top: 20px;
}

.subCategoryWrapper .MuiGrid-container {
  justify-content: flex-start;
}

.responsiveForLoggedIn {
  height: calc(100vh - 130px);
  overflow-y: auto;
}

.loadingDivOutside {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center !important;
}

.lazy-load-image-background.blur img {
  width: 100px;
  height: 100px;
}

.subcategoryBanner .lazy-load-image-background.blur img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.subcategoryBanner .lazy-load-image-background.blur.lazy-load-image-loaded img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.categoryWrapperClass .room-details .seats-time {
  height: auto;
}

@media only screen and (max-width: 980px) {
  .categoryWrapperClass {
    height: calc(100vh - 80px);
    overflow: auto;
  }
  .categoryWrapperClass .loadingDivOutside {
    height: 100vh;
  }
  .categoryWrapperClass .scrollbarY {
    display: none !important;
  }
  .categoryWrapperClass .leftMenuWrapper {
    padding: 0px;
  }
  .categoryWrapperClass .leftMenuWrapper .subcategoriesBackButton {
    margin-left: 20px;
  }
  .categoryWrapperClass .leftMenuWrapper .subcategoriesLabel {
    margin-left: 20px;
  }
  .categoryWrapperClass .leftMenuWrapper .subcategoriesList {
    border: none;
    height: auto;
    margin-bottom: 20px;
  }
  .categoryWrapperClass .leftMenuWrapper .subcategoriesList .subcategoriesListBox {
    margin-bottom: 0px;
    border-bottom: 1px solid #c7cad9;
    border-radius: 0;
  }
  .categoryWrapperClass .leftMenuWrapper .subcategoriesList .subcategoriesListBox.active {
    border-radius: 0;
  }
  .subCategoryWrapperNotloggedIn,
  .subCategoryWrapper {
    min-height: calc(100vh - 140px);
    padding: 0px;
  }
  .subCategoryWrapperNotloggedIn.subCategory,
  .subCategoryWrapper.subCategory {
    flex-direction: column-reverse !important;
    flex-wrap: nowrap !important;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBannerBox,
  .subCategoryWrapper .subcategoryBannerBox {
    height: -moz-fit-content;
    height: fit-content;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBreadcrumb,
  .subCategoryWrapper .subcategoryBreadcrumb {
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px 10px 10px 10px;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBreadcrumb .MuiBreadcrumbs-root,
  .subCategoryWrapper .subcategoryBreadcrumb .MuiBreadcrumbs-root {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBreadcrumb li,
  .subCategoryWrapper .subcategoryBreadcrumb li {
    color: #222222;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBreadcrumb p,
  .subCategoryWrapper .subcategoryBreadcrumb p {
    font-size: 14px !important;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBreadcrumb a,
  .subCategoryWrapper .subcategoryBreadcrumb a {
    font-size: 14px !important;
    cursor: pointer;
    color: #222222;
  }
  .subCategoryWrapperNotloggedIn .loadingOutsideDiv,
  .subCategoryWrapper .loadingOutsideDiv {
    min-height: 400px;
  }
  .subCategoryWrapperNotloggedIn .d-full,
  .subCategoryWrapper .d-full {
    max-height: none;
    margin: 10px;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde,
  .subCategoryWrapper .d-full .subcategoryPostOutisde {
    padding: 0px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryPost,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryPost {
    width: 100%;
    flex-basis: auto;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryPost .subcategoryName,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryPost .subcategoryName {
    font-size: 20px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 10px 5px 10px;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryButton,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryButton {
    display: none;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .roomCount,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .roomCount {
    display: none;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryDesc,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryDesc {
    min-height: 50px;
    height: auto;
    padding: 0px 10px;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryForMobile,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryForMobile {
    margin-top: 10px;
    flex-basis: auto;
    border-top: 1px solid #aeaeae;
    display: flex;
    padding: 10px;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryForMobile .subcategoryButton,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryForMobile .subcategoryButton {
    display: block;
    font-weight: 600;
    font-size: 20px;
    text-align: right;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryForMobile .roomCountForSmall,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryForMobile .roomCountForSmall {
    color: #c7cad9;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .subCategoryWrapperNotloggedIn .d-full .subcategoryPostOutisde .subcategoryForMobile .roomCount,
  .subCategoryWrapper .d-full .subcategoryPostOutisde .subcategoryForMobile .roomCount {
    display: none;
  }
  .subCategoryWrapperNotloggedIn .noRoom,
  .subCategoryWrapper .noRoom {
    min-height: 400px;
    font-size: 18px;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBanner,
  .subCategoryWrapper .subcategoryBanner {
    border-radius: 0px;
  }
  .subCategoryWrapperNotloggedIn .subcategoryBanner .MuiSkeleton-text,
  .subCategoryWrapper .subcategoryBanner .MuiSkeleton-text {
    transform: scale(1, 1);
  }
  .subCategoryWrapperNotloggedIn .subcategoryBanner img,
  .subCategoryWrapper .subcategoryBanner img {
    border-radius: 0px;
  }
  .responsiveBoxForCategories {
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1360px) {
  .subCategoryWrapperNotloggedIn {
    /* margin-left: 10% !important;
    margin-right: 10% !important; */
    padding: 20px;
    padding-top: 20px;
  }
  /* .subcategoriesList {
     height: calc(100vh - 20px) !important;
  } */
  .responsiveBoxForCategories {
    margin-left: 11.5% !important;
    height: calc(100vh - 200px);
    margin-right: 11.5% !important;
    overflow-y: auto;
  }
}
.mainContainer {
  background-color: #fcfbfc;
  display: flex;
  background-color: #fcfbfc;
  padding-top: 80px;
  background: #fcfbfc;
  height: 100%;
}

.column1-main-box {
  text-align: center;
  padding: 1px 10px;
}

.column1-child-box1 {
  margin: auto;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

@media (max-width: 850px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

@media (max-width: 450px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

#profilePic {
  width: 70px;
  height: 70px;
}

#profilePic0 {
  width: 58px;
  height: 58px;
  margin-right: 15px;
}

.profileChildIcon {
  width: 18px;
}

.hearts {
  margin-top: 10px;
  color: #c4c6cb;
}

#report-popup {
  padding: 10px;
  margin: 0px;
  background-color: #f1f1f1;
}

#report-popup li {
  padding: 12px;
  border-bottom: #c1c1c1 solid 1px;
  list-style: none !important;
  min-width: 165px;
}

#report-popup li:last-of-type {
  border-bottom: none;
}

.hearts1 {
  margin-top: 15px;
  color: #c4c6cb;
}

.hearts2 {
  margin-top: 10px;
  color: #010039;
  font-weight: 700;
}

.column1-child-box1-clild1 {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

#profile-name {
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  color: #010039;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.slider0 {
  margin-top: -10px;
}

.slider0 .MuiSlider-track {
  height: 4px;
  display: block;
  position: absolute;
  border-radius: 3px;
  background-color: #fbca0c;
  color: #d9d9d9;
}

.slider0 .MuiSlider-rail {
  width: 100%;
  height: 4px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 4px;
  background-color: gray;
}

.slider0 .MuiSlider-thumb {
  position: initial !important;
}

.subcategories-box {
  justify-content: space-between;
  width: 100%;
}

#connected-btn {
  background-color: #010138;
  color: ffffff;
  height: 35px;
  border-radius: 16px;
  width: 100px;
}

.touch-points {
  color: #010138;
  font-weight: 500;
  cursor: pointer;
}

/* //middle box css */

.middle-box {
  border-radius: 15px;
  border: 1px solid #dbd8d8;
  background-color: white;
}

#middel-box-lable {
  word-break: break-word;
  font-size: 16px;
  font-weight: 500;
  color: #010039;
}

#middel-box-text {
  font-size: 16px;
  font-weight: 500;
  color: #707070;
}

/* THird column */

.right-side-btn {
  text-transform: none;
  margin-top: 30px;
  width: 90%;
  border: 1px solid #dbd8d8 !important;
  /* margin-left: 10px !important; */
}

.right-side-btn-div .MuiButton-root {
  border-radius: 12px !important;
  width: 100%;
  padding: 16px 16px 16px 10px !important;
  background: #fff !important;
}

.column1-main-box {
  padding: 1px 30px;
}

@media (max-width: 850) {
  .column1-main-box {
    padding: 1px 10px;
  }
}

@media (max-width: 450) {
  .column1-main-box {
    padding: 1px 5px;
  }
}

.column2-child-box2 {
  width: 90%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbarY.right {
  height: calc(100vh - 100px);
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden
}

.option_btn {
  font-weight: "bold";
  text-transform: capitalize;
  font-size: 18px;
  width: 200px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  padding: 10px;
}

.public-crd {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.crd-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #394c6a;
  line-height: 70px !important;
}

.flg_img {
  width: 30px;
  height: 20px;
  padding: 0;
  margin: 0;
}

/* room Content */
.profile_img {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.report-resource-popup-textfield .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
  font-size: 12px;
}

.room-details {
  background: #fff;
}


@media only screen and (max-width: 900px) {
  .column2-child-box2 {
    width: 98%;
    margin-left: 2% !important;
  }

  .mainContainer {
    padding: 20px 0px;
  }

  .scrollbarY.right {
    height: calc(100vh - 110px);
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #middel-box-lable {
    text-align: left;
  }
}
/*****modals*****/
#alert-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FFFAA0;
}

#ask-to-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#must-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#max-alerts-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#already-asked-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#self-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}
#green-modal .MuiBackdrop-root{
    opacity:.5!important;
    background-color: #c6edc6;
}
/**maxalert+alreadyasked******/
.red-border-card-modal {
    font-size: 1rem!important;
    padding: 1rem;
    width: 22rem;
    border: 3.5px solid #FA8072;
    border-radius: 15px !important;
    position: absolute;
    top: 35%;
    right: 55%;
    font-weight: 500!important;
    color: rgba(0, 0, 0, 0.42)!important;;
}

/***askToLeave+alert****/
#modal-text {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.modal-icon {
    margin-right: 6px;
}
.modal-icon-2{
    margin:0 1rem;
}

.border-card-modal {
    text-align: center;
    border: 3.5px solid;
    border-radius: 15px !important;
    position: absolute;
    /* right: 55%;
    top: 15%; */
    right:45%;
    top:30%;
}

/****self+mustleave****/

.red-card-modal {
    position: absolute;
    width: 22rem;
    padding: 1rem;
    top: 40%;
    right: 40%;
    border-radius: 15px !important;
}

#leave-button-modal {
    margin-top: 7px;
    background-color: #ff5e47;
    color: white;
    border-radius: 5px;
    font-size: large;
    text-transform: none;
    width: 100%;
}

@media only screen and (min-width:350px) and (max-width:650px ){
    .border-card-modal {
        top:32%;
        right:19%;
        width:15rem;
    }
    .red-border-card-modal{
        right:25%;
    }
    .red-card-modal{
        right: 32%;
        width: 15rem;
    }
}
/***roomEnding******/
#modal-window .MuiBackdrop-root {
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}

#modal-window .modal-card {
    width: 19rem;
    position: absolute;
    top: 35%;
    right: 40%;
    border-radius: 8px;
}

.price-and-wallet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.blue-and-bold {
    color: #150c44;
    font-weight: 600;
}

.wallet {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 3px;
}

.card-text-modal {
    margin-bottom: 5px;
    color: #c0bfbf;
    font-size: .95rem;
}

#modal-window .card-button-modal {
    text-transform: none;
    border-radius: 8px;
    width: 50%;
    padding: 10px 0!important;
    margin-bottom: 5px;
}

@media only screen and (min-width:350px) and (max-width:600px ){
    #modal-window .modal-card{
        width: 15rem;
        top:35%;
        right: 18%;
    }
}
/******paper********/
#sidebar {
  width: 30%;
  border: 0 !important;
}

#sidebar .paper {
  margin: 1px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.paper .MuiPaper-rounded {
  border-radius: 15px;
}

.paper .tab-label {
  width: 50%;
  font-size: large;
  font-weight: bold;
  color: #262556;
}

/***********tabs**********/

.MuiTabs-indicator {
  display: none;
}

.MuiTab-wrapper {
  text-transform: none;
  margin: 6px 0;
}

.active-tab .MuiTab-wrapper {
  background-color: #f5f5f7;
  padding: 6%;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 500;
}

.disabled-tab .MuiTab-wrapper {
  color: #c0bfbf;
  font-size: 1rem;
  font-weight: 500;
}

/***tab****/
#tab .MuiBox-root {
  padding: 0 !important;
  text-align: left;
}
#tab .MuiTabs-flexContainer {
  justify-content: space-evenly;
}

/*****participantTab*****/
#participants {
  border-radius: 0 0 8px 8px !important;
}

#participants .MuiCardContent-root {
  padding-bottom: 0;
}

#participants .label {
  font-weight: 500;
  color: #262556;
  font-size: 1rem;
}

.userdata-and-hearts .MuiAvatar-root {
  width: 56px;
  height: 56px;
  border-radius: 15px;
}

.userdata-and-hearts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-username {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-avatar {
  margin-right: 10px;
}

.user-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#heart {
  color: #ffcb00;
}

#heart .MuiRating-label {
  padding: 0 3px;
}

#heart .MuiRating-iconActive {
  transform: none;
}

.x-field {
  margin: 0.4rem 0 0.8rem;
}

.x-field .MuiOutlinedInput-root {
  border-radius: 15px;
  font-size: 0.9rem;
}

#connection-button {
  color: white;
  padding: 10px 0;
  border-radius: 15px;
  font-size: 1rem;
  letter-spacing: 0.15rem;
}

#participants .MuiCardActions-root {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
}

/***message Tab**/
#message-tab {
  height: 81vh;
  overflow: auto;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-start; */
}

/****message Text**/
.other-message-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  word-break: break-all;
}

.other-message-body-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #c0bfbf;
  border-radius: 20px;
  padding: 5px 12px 5px 12px;
  color: #2d2e5f;
  font-size: 0.9rem;
}

.your-message-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
}

.your-message-body-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #e7f1ff;
  border-radius: 20px;
  padding: 5px 12px 5px 12px;
  color: #2d2e5f;
  font-size: 0.9rem;
}

.time-stamp {
  font-size: 0.75rem;
}

.fade {
  color: #c0bfbf;
}

/****message-footer**/
.message-box {
  width: 100%;
  margin: 6px 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 5px 0;
}

.fade-back {
  background-color: #f5f5f7;
}

.message-tool {
  border-radius: 15px;
  text-align: center;
}

.message-tool button {
  padding: 11px !important;
  min-width: 30px;
  border-radius: 10px !important;
}

.text-message .MuiOutlinedInput-root {
  border-radius: 8px;
}

#text-message {
  padding: 15px;
  font-size: 0.9rem;
}

.epr-emoji-category .epr-emoji-category-label {
  display: none !important;
}

#emoji-picker aside.EmojiPickerReact.epr-main {
  border: none;
}

@media screen and (min-width: 1100px) and (max-width: 1250px) {
  .active-tab {
    padding-right: 3%;
    padding-left: 3%;
  }
  .disabled-tab {
    padding-right: 3%;
    padding-left: 3%;
  }
  #connection-button {
    padding: 10px 0 !important;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
  }
  #tab .MuiTab-root {
    padding: 6px 4px;
  }
}

@media screen and (min-width: 500px) and (max-width: 1101px) {
  #sidebar {
    width: 95%;
  }
  #tab .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 500px) {
  .active-tab {
    padding-right: 2%;
    padding-left: 2%;
  }
  .disabled-tab {
    padding-right: 2%;
    padding-left: 2%;
  }
  .paper .tab-label {
    font-size: 0.8rem;
  }
  #sidebar {
    width: 95%;
  }
  #tab .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
  #connection-button {
    font-size: 0.8rem;
    letter-spacing: 0;
  }
}

.resource-links div {
  cursor: pointer;
}

.resource-links textarea {
  cursor: pointer;
}

.resource-links a div div {
  border-radius: 10px !important;
  padding: 15px 12px;
}

.resource-links:not(:last-child) {
  margin-bottom: .5rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
b,
ul,
li,
ol {
  font-family: "Twemoji Country Flags", 'Poppins' !important;
}

#room {
  margin-top: 4.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #f5f5f5;
  height: 92%;
}

#room-error-msg {
  color: red;
  background-color: #f5f5f5;
}

#error-msg svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
  color: red !important;
}

@media screen and (max-width: 750px) {
  #room {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: 60px; /* header height */
    background: #f5f5f5;
  }
  
  #ice-breaker {
    width: 70vw;
    left: 0;
    top: 4rem;
    font-size: .8rem;

    padding: .5rem;
  }

  .feedback-sorry-btn-outter {
    justify-content: center;
    align-items: center;
  }

  .feedback-refund-outter {
    width: auto;
  }

  .refund-message-container {
    width: auto;
    height: auto;
  }
}

.myWallet {
  height: calc(100vh - 65px);
  margin-top: 65px !important;
  overflow: hidden;
  justify-content: center;
  border-top: 1px solid #E0E0E0;
}
.myWallet .myWalletInside {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #E0E0E0;
}
.myWallet .myWalletInsideRight {
  height: 100%;
  background-color: #FBFBFB;
  padding: 0px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  border: none;
  color: #232323;
}
.myWallet .myWalletModerator {
  margin-top: 20px;
  overflow-y: scroll;
  padding-right: 10px;
  transition: 0.5s;
  margin-right: -28px;
}
.myWallet .myWalletModerator::-webkit-scrollbar-track-piece {
  background-color: white;
}
.myWallet .myWalletModerator::-webkit-scrollbar-track {
  background: #fff;
  box-shadow: none;
}
.myWallet .myWalletModerator .MuiSlider-track {
  background: white;
}
.myWallet .walletBalanceMain {
  margin-left: auto;
  margin-top: 30px;
  height: 110px;
  border-radius: 12px;
  border: 1px solid;
  background-color: #FFFAE5;
  border-color: #FFCB00;
  font-weight: 600;
  padding: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}
.myWallet .walletBalanceMain img {
  position: absolute;
  top: 0%;
  right: 0%;
}
.myWallet .walletBalanceMain .walletIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}
.myWallet .walletBalanceMain.moderator {
  border-color: #010039;
  background: rgba(1, 0, 57, 0.1);
}
.myWallet .walletBalanceMain.moderator .balance {
  font-size: 16px;
  height: 22px;
  display: flex;
  align-items: flex-start;
  color: #7F8E9D;
}
.myWallet .walletBalanceMain.moderator .coinAmount {
  display: flex;
  align-items: flex-end;
  height: 43px;
  font-size: 32px;
  color: #222255;
}
.myWallet .walletBalanceMain.moderator .earnedCoin {
  font-size: 18px;
  height: 30px;
  padding: 0px 10%;
  color: #7F8E9D;
}
.myWallet .balance {
  font-size: 16px;
  height: 22px;
  display: flex;
  align-items: flex-start;
  color: #7F8E9D;
}
.myWallet .coinAmount {
  display: flex;
  align-items: flex-end;
  height: 43px;
  font-size: 32px;
  color: #222255;
}
.myWallet .earnedCoin {
  font-size: 18px;
  height: 30px;
  padding: 0px 10%;
  color: #7F8E9D;
}
.myWallet .refillWalletButton {
  margin-top: 20px;
  cursor: pointer;
  padding: 10px;
  background-color: #FFCB00;
  color: #010039;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
}
.myWallet .refillWalletButton.admin {
  background-color: #010039;
  color: #fff;
}
.myWallet .p40Flex {
  height: 100%;
  position: relative;
  padding: 0px 40px;
}
.myWallet .walletTopWrapper {
  border-bottom: 1px solid #E0E0E0;
  padding: 20px 40px;
}
.myWallet .walletTopWrapper .walletBackButton {
  cursor: pointer;
  font-size: 24px;
  display: flex;
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 15px;
  align-items: center;
  padding-left: 0px;
  text-align: left;
}
.myWallet .walletTopWrapper .walletBackButton img {
  margin-right: 13px;
}
.myWallet .transactionLabel {
  width: 100%;
  font-size: 22px;
  color: #222255;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}
.myWallet .transactionSecondLabelBox {
  display: flex;
  justify-content: space-between;
}
.myWallet .yCoinLabel {
  font-size: 18px;
  color: #8e8e8e;
  display: block;
  font-weight: 600;
}
.myWallet .transaction {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.myWallet .transactionBox {
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
  display: flex;
  transition: 0.3s;
}
.myWallet .transactionBox:hover {
  background-color: #fff;
}
.myWallet .transactionLeftBox {
  width: 60px;
}
.myWallet .transactionMiddleBox {
  width: 100%;
}
.myWallet .transactionRight {
  color: #222255;
  width: 100px;
  text-align: right;
}
.myWallet .transactionType {
  display: block;
  width: 100%;
  color: #222255;
}
.myWallet .transactionDate {
  display: block;
  width: 100%;
  color: #c7cad9;
}
.myWallet .feedbackHighlight {
  color: #222255;
  cursor: pointer;
  text-decoration: underline;
}
.myWallet .feedbackOutside {
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}
.myWallet .feedbackDiv {
  width: 60%;
  padding: 10px 10px;
  font-size: 17px;
  text-align: center;
  color: #8e8e8e;
  font-weight: 500;
}

.sliderBaseWallet {
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 25px;
  background-color: #f9f9f9;
  display: flex;
  font-size: 16px;
  font-weight: 600;
}
.sliderBaseWallet .insideButtons {
  width: 50%;
  height: 100%;
  color: #a2a2a2;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.sliderBaseWallet .insideButtons.active {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1019607843);
  color: #1e3354;
  cursor: default;
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .accountDetailsOutside .accountDetailsFrom {
    width: 100%;
  }
  .myWallet.mobileMyWallet {
    height: calc(100vh - 65px);
    margin-top: 65px !important;
    overflow: hidden;
    justify-content: center;
  }
  .myWallet.mobileMyWallet .topBar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #E0E0E0;
  }
  .myWallet.mobileMyWallet .topBar .walletBackButton {
    padding-bottom: 0px;
  }
  .myWallet.mobileMyWallet .topBar img {
    position: absolute;
    left: 20px;
    bottom: 18px;
  }
  .myWallet.mobileMyWallet .wrapperInside {
    padding: 20px;
    overflow: auto;
    height: calc(100vh - 110px);
  }
  .myWallet.mobileMyWallet .wrapperInside .walletBalanceMain {
    padding: 6px 20px;
    height: auto;
    margin-top: 0px;
  }
  .myWallet.mobileMyWallet .wrapperInside .moderatorExplainText {
    font-size: 10px;
  }
  .myWallet.mobileMyWallet .wrapperInside .ratingDesc {
    margin-top: 15px;
  }
  .myWallet.mobileMyWallet .wrapperInside .earnedCoinsCurrentRating {
    font-size: 12px;
  }
  .myWallet.mobileMyWallet .wrapperInside .timeAndHearth {
    font-size: 10px;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionLeftBox {
    display: flex;
    align-items: center;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionLeftBox img {
    width: 30px !important;
    height: 30px !important;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionLabel {
    display: none;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionSecondLabelBox {
    font-size: 16px;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionType {
    font-size: 14px;
  }
  .myWallet.mobileMyWallet .wrapperInside .transactionDate {
    font-size: 12px;
  }
  .myWallet.mobileMyWallet .wrapperInside .refillWalletButton {
    border-radius: 24px;
  }
  .myWallet.mobileMyWallet .wrapperInside .enterBankDetailsButton {
    border-radius: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .myWallet.mobileMyWallet .wrapperInside .bankDetailsOutsideBox {
    padding: 0px;
    width: 100%;
  }
  .myWallet.mobileMyWallet .wrapperInside .bankDetailsOutsideBox .bankDetailsBox {
    margin-top: 0px;
  }
  .myWallet.mobileMyWallet .wrapperInside .d-flex {
    flex-direction: column-reverse;
  }
  .myWallet.mobileMyWallet .wrapperInside .accountStatementLabels {
    display: none;
  }
  .myWallet.mobileMyWallet .wrapperInside .walletLinks {
    justify-content: space-between;
    display: flex;
    padding-top: 20px;
    align-items: center;
  }
  .myWallet.mobileMyWallet .wrapperInside .walletLinks .text {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #010039;
    padding: 5px 10px;
  }
  .myWallet.mobileMyWallet .wrapperInside .walletLinks .text span {
    display: block;
    color: #c7cad9;
    font-size: 12px;
  }
  .myWallet.mobileMyWallet .wrapperInside .walletLinks img {
    width: 30px;
    height: 30px;
  }
  .fade-enter {
    transform: translateX(-150%);
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 200ms ease-in, transform 500ms ease-in;
  }
  .fade-enter-active {
    transform: translateX(0);
    opacity: 1;
  }
  .fade-exit {
    transform: translateX(0);
    opacity: 1;
    transition: opacity 200ms ease-out, transform 500ms ease-out;
  }
  .fade-exit-active {
    transform: translateX(-150%);
    width: 100%;
    position: absolute;
    opacity: 0;
  }
  .fade-right-enter {
    transform: translateX(150%);
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 200ms ease-in, transform 500ms ease-in;
  }
  .fade-right-enter-active {
    transform: translateX(0);
    opacity: 1;
  }
  .fade-right-exit {
    transform: translateX(0);
    opacity: 1;
    transition: opacity 200ms ease-out, transform 500ms ease-out;
  }
  .fade-right-exit-active {
    transform: translateX(150%);
    width: 100%;
    position: absolute;
    opacity: 0;
  }
}
.fade-enter {
  transform: translateX(-150%);
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 400ms ease-in, transform 1000ms ease-in;
}

.fade-enter-active {
  transform: translateX(0);
  opacity: 1;
}

.fade-exit {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 400ms ease-out, transform 1000ms ease-out;
}

.fade-exit-active {
  transform: translateX(-150%);
  width: 100%;
  position: absolute;
  opacity: 0;
}

.fade-right-enter {
  transform: translateX(150%);
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 400ms ease-in, transform 1000ms ease-in;
}

.fade-right-enter-active {
  transform: translateX(0);
  opacity: 1;
}

.fade-right-exit {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 400ms ease-out, transform 1000ms ease-out;
}

.fade-right-exit-active {
  transform: translateX(150%);
  width: 100%;
  position: absolute;
  opacity: 0;
}
.accountDetailsOutside {
   width: 100%;
   position: absolute;
   height: calc(100vh - 66px);
   top:66px;
   display: flex;
   justify-content: flex-end;
   right: 0px;
   z-index: 99;
   -webkit-backdrop-filter: blur(5px);
           backdrop-filter: blur(5px);
   background-color: rgba(0, 0, 0, 0.2);
}

.accountDetailsFrom {
   overflow-y: scroll;
   background-color: #fff;
   padding: 20px;
   position: relative;
   width: 40%;
   height: 100%
}

.accountDetailsFrom input {
   background-color: #fff;
   box-shadow: none;
   padding: 12px;
   border: 1px solid #e5e5e5;
   border-radius: 8px;
   width: 100%;
   font-size: 14px;
   transition: .5s;
   font-weight: 500;
}

.accountDetailsFrom label {
   font-size: 16px;
   color: #222255;
   font-weight: 500;
   margin-bottom: 5px;
   margin-top: 10px;
   display: block;
   width: 100%;
}

.walletBackButton {
   cursor: pointer;
   font-size: 22px;
   display: flex;
   font-weight: 500;
   padding-bottom: 15px;
   align-items: center;
   padding-left: 0px;
   text-align: left;
}

.walletBackButton img {
   margin-right: 13px;
   width: 15px;
   height: 15px;
}

.detailButton {
   width: 100%;
   margin-top: 30px;
   margin-bottom: 0px;
   cursor: pointer;
   padding: 16px;
   background-color: #232344;
   color: #fff;
   font-size: 18px;
   border-radius: 12px;
   text-align: center;
   border: none;
   font-weight: 600;
}

.detailButtonDisabled {
   width: 100%;
   margin-top: 30px;
   cursor: not-allowed;
   padding: 16px;
   background-color: #e5e5e5;
   color: #fff;
   font-size: 18px;
   border: none;
   box-shadow: none;
   border-radius: 12px;
   text-align: center;
   font-weight: 600;
}

.detailsErrorBox {
   font-size: 13px;
   margin-top: 10px;
   color: red;
   height: 20px;
   text-align: left;
   font-weight: 500;
}

.accountDetailsFrom .error {
   background-color: #fff;
   margin-left: 5px;
   box-shadow: none;
   padding: 15px;
   border: 1px solid red;
   border-radius: 8px;
   width: 100%;
   font-size: 15px;
   transition: .5s;
   font-weight: 500;
}

.loadingMask {
   right: 0px;
   position: absolute;
   width: 40%;
   z-index: 99;
   -webkit-backdrop-filter: blur(4px);
           backdrop-filter: blur(4px);
   height: 100%;
   background-color: transparent;
}

@media only screen and (max-width: 900px) {
   .loadingMask {
      width: 100%;
   }
}
/*ACCOUNT STATEMENT */

.enterBankDetailsButton {
   cursor: not-allowed;
   padding: 10px;
   font-size: 16px;
   font-weight: 500;
   width: 100%;
   text-align: center;
   border: 2px solid #eaeaea;
   background-color: #eaeaea;
   border-radius: 8px;
   color: #8e8e8e;
   margin-top: 50px;
   margin-bottom: 50px;
}

.enterBankDetailsButton.active {
   cursor: pointer;
   padding: 10px;
   font-size: 16px;
   font-weight: 500;
   width: 100%;
   text-align: center;
   border: 2px solid #222255;
   background-color: #eaeaea;
   border-radius: 8px;
   color: #222255;
   margin-top: 50px;
   margin-bottom: 50px;
}

.accountStatementBox {
   width: 100%;
   display: flex;
   flex-direction: column;
}

.accountStatementLabels {
   justify-content: space-between;
   display: flex;
   color: #8e8e8e;
   font-size: 18px;
   font-weight: 600;
}

/* RATING */

.ratingBox {
   margin-top: 30px;
   display: flex;
   flex-direction: column;
}

.earnedCoinsCurrentRating {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   font-weight: 600;
}

.earnedAndTime {
   display: flex;
   flex-direction: column;
}

.moderatorExplainText {
   font-size: 14px;
   width: 100%;
   font-weight: 500;
}

.moderatorExplainText ul {
   padding: 0px 20px;
}

.moderatorExplainText span {
   color: #222255;
}

.timeAndHearth {
   margin-top: 3px;
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   font-weight: 500;
   color: rgba(1, 0, 57, 0.4);
}

.ratingDesc {
   padding:12px;
   border-radius: 8px;
   border: 1px solid rgba(1, 0, 57, 0.4);
   margin-top: 30px;
   color: rgba(1, 0, 57, 0.4);
   font-weight: 600;
   font-size: 14px;
   display: flex;
}

.ratingDesc img {
   margin-top: 5px;
   margin-right: 12px;
   width: 20px;
   height: 20px;
}

.accounStatementDataBox {
   border-bottom: 1px solid #e5e5e5;
   margin-top: 20px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: column;
   font-weight: 600;
}

.accountDataTypeAndPrice {
   display: flex;
   justify-content: space-between;
   color: #222255;
   font-size: 18px;
   font-weight: 600;

}

.accountDataTıme {
   margin-top: 5px;
   color: #8e8e8e;
   font-size: 16px;
}

.accountParticipants {
   margin-top: 5px;
   font-size: 16px;
   color: #8e8e8e;
}

.accountCompleted {
   margin-top: 5px;
   font-size: 16px;
   grid-gap: 10px;
   gap: 10px;
   display: flex;
   color: #8e8e8e;
}

.heartCount {
   display: flex;
   align-items: center;
}

.hearthCountBad {
   display: flex;
   align-items: center;
}

.heartCount img {
   margin-right: 4px;
   width: 20px;
   height: 20px;
}

.hearthCountBad img{
   margin-right: 4px;
   width: 20px;
   height: 20px;
   filter: grayscale(100%)
}

.bankDetailsOutsideBox {
   padding-left: 20px;
   width: 50%;
}

.bankDetailsBox {
   background-color:#fff ;
   margin-top: 24px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   border-radius: 12px;
   border: 1px solid #E0E0E0;
   font-size: 14px;
   font-weight: 500;
   position: relative;
}

.bankDetailEditButton {
   cursor: pointer;
   position: absolute;
   right: 3px;
   top: 15px;
   width: 25px;
   height: 25px;
}

.bankDetailEditButton img {
   width: 15px;
   height: 15px;
}

.bankDetailBoxUserName {
   padding: 12px;
   border-bottom: 1px solid #E0E0E0;
   color: #222255;
}

.bankDetailInfo {
   padding: 4px 12px;
   color: #8e8e8e;
}
@charset "UTF-8";
.mobileAccordion {
  /* RATING */
}
.mobileAccordion .myAccordion {
  padding: 0px !important;
  border: none !important;
  margin-top: 0px;
  border-top: 1px #e5e5e5 solid !important;
  border-radius: 0px !important;
}
.mobileAccordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
}
.mobileAccordion .myAccordion .MuiIconButton-label svg {
  fill: #222222;
}
.mobileAccordion .myAccordion .MuiIconButton-edgeEnd {
  margin: 0px;
}
.mobileAccordion .myAccordion::-webkit-scrollbar {
  width: 8px;
  box-shadow: none;
}
.mobileAccordion .myAccordion::-webkit-scrollbar-track {
  width: 8px;
  box-shadow: none;
}
.mobileAccordion .myAccordionInside {
  padding: 0px !important;
  display: block !important;
}
.mobileAccordion .accordionHeader {
  padding: 0px !important;
}
.mobileAccordion #panel1d-header {
  padding: 0px !important;
}
.mobileAccordion .accordionModel {
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}
.mobileAccordion .accordionExplain {
  margin-top: 6px;
  color: #222255;
  font-weight: 700;
  font-size: 20px;
}
.mobileAccordion .accordionDesc {
  margin-top: 10px;
  color: #8e8e8e;
  font-size: 18px;
}
.mobileAccordion .ratingBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.mobileAccordion .earnedCoinsCurrentRating {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
}
.mobileAccordion .earnedAndTime {
  display: flex;
  flex-direction: column;
}
.mobileAccordion .moderatorExplainText {
  font-size: 14px;
  width: 100%;
  font-weight: 500;
}
.mobileAccordion .moderatorExplainText ul {
  padding: 0px 20px;
}
.mobileAccordion .moderatorExplainText span {
  color: #222255;
}
.mobileAccordion .timeAndHearth {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: rgba(1, 0, 57, 0.4);
}
.mobileAccordion .ratingDesc {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(1, 0, 57, 0.4);
  margin-top: 30px;
  color: rgba(1, 0, 57, 0.4);
  font-weight: 600;
  font-size: 14px;
  display: flex;
}
.mobileAccordion .ratingDesc img {
  margin-top: 5px;
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
.mobileAccordion .accounStatementDataBox {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}
.mobileAccordion .accountDataTypeAndPrice {
  display: flex;
  justify-content: space-between;
  color: #222255;
  font-size: 14px;
  font-weight: 600;
}
.mobileAccordion .accountDataTıme {
  margin-top: 5px;
  color: #8e8e8e;
  font-size: 14px;
}
.mobileAccordion .accountParticipants {
  margin-top: 5px;
  font-size: 14px;
  color: #8e8e8e;
}
.mobileAccordion .accountCompleted {
  margin-top: 5px;
  font-size: 14px;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  color: #8e8e8e;
}
.mobileAccordion .heartCount {
  display: flex;
  align-items: center;
}
.mobileAccordion .hearthCountBad {
  display: flex;
  align-items: center;
}
.mobileAccordion .heartCount img {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}
.mobileAccordion .hearthCountBad img {
  margin-right: 4px;
  width: 14px;
  height: 14px;
  filter: grayscale(100%);
}
html,
body,
#root,
#root > div {
  overflow-y: auto;
}

.menu-list > ul {
  margin-bottom: 0px !important;
}

.nav-menus {
  padding-bottom: 10px;
}

.nav-menus:hover {
  cursor: pointer;
  border-bottom: 1px solid #ffcb00;
}

.enter-btn {
  background: #ffcb00 !important;
}

.desc-syl {
  color: #b5b3be;
  font-size: 20px;
}

#para-syl {
  color: #000;
}

.store-btn {
  width: 10px;
  height: 40px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  width: 350px;
  background-color: #ffcb00;
  color: #010039;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -130px;
  left: -2px;
  font-size: 18px;
  padding: 16px 8px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcb00 transparent transparent transparent;
}

.dotted-line-vertical {
  position: relative;
  width: 20px;
}

.dotted-line-vertical::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}
.dotted-line-vertical::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}

.verification-outer {
  width: 60%;
  border-radius: 15px;
  margin: 60px auto;
  height: -moz-max-content;
  height: max-content;
  text-align: center;
  max-width: 95vh;
}

.cancel-out {
  text-align: right;
  z-index: 1;
}

.cancel-out .cancel-icon {
  margin: 15px !important;
  height: 50px;
  width: 50px;
  font-size: 35px !important;
  cursor: pointer;
}

.otp-container {
  margin: 0px 20%;
}

.otp-container p {
  font-size: 15px !important;
  color: #00000052 !important;
  font-weight: 400 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-container input {
  width: 50px;
  margin: 10px 8px;
  text-align: center;
  font-size: 25px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0000006e;
  -webkit-appearance: textfield;
}

.submit-button-div {
  width: 100%;
  margin: 0px auto;
}

.submit-button-div .submit-btn {
  width: 100%;
  color: #fff !important;
  background: #150c44 !important;
  font-size: 15px;
  margin: 15px 0px;
  border-radius: 10px;
  box-shadow: unset;
  text-transform: unset;
}

.setup-profile {
  margin: 0px auto;
  width: 100%;
  display: block !important;
}

.setup-profile .setup-submit {
  width: 50%;
}

.bottom-text .text-small {
  font-size: 16px;
}

.bottom-text .text-big strong {
  /* color:#150c44; */
  font-weight: 500;
}

.MuiAlert-root {
  padding: 6px 35px !important;
}

.MuiAlert-filledSuccess {
  color: #000 !important;
  background-color: #95d17c !important;
}

.MuiPaper-elevation6 {
  box-shadow: none !important;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 75px !important;
}

.MuiAlert-root {
  border-radius: 10px !important;
}

.MuiAlert-filledError {
  background-color: #ff5e60 !important;
}

.padding-24 {
  padding: 24px;
}

.MuiStepLabel-label {
  color: rgb(0 0 0 / 22%) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiOutlinedInput-root .dropdown-value {
  padding: 14px;
  color: #000;
}

.MuiStepConnector-lineHorizontal {
  border-top-style: solid !important;
  border-top-width: 4px !important;
}

.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiPaper-elevation0 .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  font-size: 30px !important;
}

.MuiStepConnector-line {
  display: block !important;
  /* border-color: #150c44 !important; */
}

.verification-inner .card-header span {
  font-size: 29px !important;
  font-weight: 500 !important;
  color: #150c44;
}

.verification-inner .AccountCircleIcon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  padding: 1px;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.verification-inner .card-contain {
  text-align: left;
  color: #150c44;
  font-weight: 500 !important;
}

.verification-inner .MuiOutlinedInput-root {
  position: relative;
  border-radius: 12px !important;
  margin: 5px 0px 5px 0px !important;
}

.blur-placeholder .MuiInputBase-input {
  opacity: 0.5;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffd33a !important;
}

.selected-chip {
  margin-top: 5px;
  border-radius: 10px !important;
  background: #150c44 !important;
  color: #fff !important;
  width: 270px;
}

.verification-outer {
  overflow: hidden;
}

.verification-inner.MuiPaper-elevation5 {
  box-shadow: none !important;
}

.inner-grid .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiPaper-elevation5 {
  border-radius: 20px !important;
}

.small-label {
  font-size: 11px;
  opacity: 0.5;
  color: #000;
}

.card-contain .outer-grid .MuiGrid-root {
  margin-bottom: 10px;
}

.welcome-inner {
  padding: 50px;
  background-color: #fff;
  background-image: url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  /* background-position:  left top, 450px 320px;
    background-size: 40% 40% , 45% 45% !important; */
  background-position: 0px -28px, 658px 481px;
  background-size: 29% 37%, 34% 43% !important;
}

.outer-div {
  background: transparent;
  margin: 0% 16%;
  font-family: 'Roboto', sans-serif;
}

.paragraph {
  font-size: 20px;
  color: #7d7b7b;
}

.submit-btn-welcome {
  width: 50%;
  margin-top: 15px !important;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.heading {
  font-size: 15px;
  font-weight: 500;
}

.welcome-inner .heading h2 {
  font-weight: 500;
  font-size: 29px;
}

.onboard-heading p {
  font-size: 30px;
  font-weight: 500;
  margin: 15px;
  margin-top: 20px;
}

.onboard-heading h3 {
  font-weight: 400;
  font-size: 20px;
  margin-top: 29px;
  color: #010038fa;
}

.onboard-heading .onboard-subheading {
  font-size: 17px !important;
  color: gray !important;
  text-align: justify;
}

.noPadding .MuiStepper-root {
  display: flex;
  padding: 0px !important;
}

.h3-tag {
  font-size: 20px;
  /* margin-bottom: -20px; */
  font-weight: 600;
  margin-bottom: -9px;
}

.earnPoint-para p {
  font-size: 14px;
  color: #aaa4a4;
  font-weight: 500;
}

.submit-btn-onboard {
  width: 100%;
  border-radius: 10px !important;
  margin-top: 30px;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.skip-btn {
  margin: 30px 0px 75px 0px;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 400;
  color: #aaa4a4;
  cursor: pointer;
}

/* Onboarding css */
.div-container {
  margin: 0px auto;
  width: 50%;
}

.div-container .textField {
  margin: 9px 0px;
  border-radius: 10px !important;
}

.div-container .textField .MuiInputBase-root {
  height: 50px;
}

.div-container .textField .MuiOutlinedInput-root {
  position: relative;
  border-radius: 10px !important;
}

.dot-icon {
  margin: 20px;
  margin-top: 50px;
}

.inactive-stepper {
  color: #80808070;

  width: 25px !important;
  height: 16px !important;
}

.active-stepper {
  font-size: 12px;
  color: #150c44;
  width: 25px !important;
  height: 16px !important;
}

.bottom-text {
  margin: 15px 0px;
  font-size: 12px;
  color: #808080c7;
  font-weight: 500;
}

.buttons-div {
  display: flex;
  justify-content: center;
}

.buttons-div .btn-multiple {
  width: -moz-max-content;
  width: max-content;
  border-radius: 10px;
  margin: 10px;
  color: #8e8c8c;
  background: #fff;
  text-transform: initial;
  border: 1px solid #cccbcb;
  box-shadow: none;
}

.buttons-div .btn-multiple.active {
  color: #fff !important;
  background: #150c44 !important;
}

.earnPoint-para .addtext-input .MuiInputBase-root {
  height: 48px !important;
  border-radius: 10px !important;
}

.addtext-input input {
  max-width: 275px;
}

/* .text-area{
    width: 100%;
    margin-bottom: 30px !important;
    height: 130px;
}
.text-area .MuiInputBase-root{
    padding: 10px !important;
    border-radius: 10px !important;

}

.text-area .MuiInputBase-root textarea{
    height: 150px !important;
} */

.text-area .MuiFormHelperText-root {
  text-align: right;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 10px !important;
  max-height: 180px !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  border-bottom: 1px solid #aaa;
  color: #aaa;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button input svg {
  color: #aaa;
}

.MuiList-root .MuiMenu-list .MuiList-padding {
  border-radius: 10px !important;
  max-height: 175px !important;
}

.MuiMenuItem-root {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.height-fill-webkit {
  height: 700px !important;
}

.MuiChip-root.selected-chip.MuiChip-deletable {
  display: flex !important;
  justify-content: space-between !important;
}

.MuiChip-root {
  height: 40px !important;
  margin-bottom: 5px !important;
}

.MuiButton-root {
  padding: 10px 37px !important;
}
.UserEditProfile {
  margin-top: 100px;
}
.UserEditProfile .editProfileBack {
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 10px;
  display: flex;
  font-weight: 600;
  padding-bottom: 5px;
  align-items: center;
  padding-left: 0px;
  text-align: left;
}
.UserEditProfile .editProfileBack img {
  margin-right: 17px;
}
.UserEditProfile .selected-chip {
  width: 100%;
}
.UserEditProfile .loadingMask {
  z-index: 1;
  left: -10px;
  position: absolute;
  width: 100%;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  height: calc(100vh - 70px);
  background-color: transparent;
}
.UserEditProfile .saveChanges {
  margin-bottom: 50px;
  padding: 15px 25px;
  width: 50%;
  color: #150c44;
  border-radius: 12px;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  margin-right: auto;
  font-weight: 600;
  font-size: 20px;
  background-color: #ffcb00;
  transition: 0.5s;
}
.UserEditProfile .saveChanges.disabled {
  background-color: #aaa;
  color: #888;
  cursor: default;
}
.UserEditProfile .saveChanges.disabled:hover {
  background-color: #aaa;
}
.UserEditProfile .saveChanges:hover {
  background-color: #ddba00;
}
.UserEditProfile .saveChanges p {
  margin: 0px;
}
.UserEditProfile .outSide {
  margin-top: 50px;
  width: 100%;
}
.UserEditProfile .outSide .col.social {
  margin-top: 20px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside {
  margin-top: 20px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .selected-subcategories-wrapper {
  height: 230px;
  overflow: auto;
  margin-top: 5px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .subAndCountryBox {
  height: 111px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial {
  display: flex;
  border: 1px solid #cccbcb;
  border-radius: 8px;
  padding: 0px 0px 0px 15px;
  align-items: center;
  margin-top: 5px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial .inputFull {
  margin-top: 15px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial .inputFull ::before {
  border-bottom: none !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial img {
  margin-right: 20px;
  height: 25px;
  width: 25px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial .facebook {
  margin-right: 20px;
  height: 25px;
  width: 15px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .colForSocial .crossIco {
  margin-left: 20px;
  height: 18px;
  width: 18px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside label {
  font-weight: 500;
  color: #222255;
  font-size: 18px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .small-label {
  font-size: 14px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside p {
  font-weight: 500;
  color: #222255;
  font-size: 18px;
  margin-top: 10px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .AccountCircleIcon {
  cursor: pointer;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .AccountCircleIcon img {
  border: #222255 2px solid;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  padding: 3px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoButton {
  font-size: 17px;
  border-radius: 12px;
  padding: 15px 80px !important;
  border: 1px solid #cccbcb;
  background-color: #fff;
  color: #cccbcb;
  box-shadow: none;
  transition: 0.5s;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoButton.active {
  color: #150c44;
  border-color: #150c44;
  background-color: #dcdcdc;
  text-transform: initial;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoText {
  font-size: 16px !important;
  background-color: #fff !important;
  color: #cccbcb !important;
  box-shadow: none !important;
  height: 62px !important;
  transition: 0.5s;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoText .MuiInputBase-formControl {
  height: 62px !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoText fieldset {
  height: 68px !important;
  border: 1px solid #cccbcb !important;
  border-radius: 8px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm {
  width: 100%;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .addSpace {
  height: 50px;
  width: 100%;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .wordsFlex {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .colModerator {
  position: relative;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .moderatorAdd {
  position: absolute;
  right: 0px;
  top: 10px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .moderatorAdd.cross img {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .moderatorAdd img {
  cursor: pointer;
  width: 21px;
  height: 21px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .pInfoLabel {
  justify-content: space-between;
  display: flex;
  color: #222255;
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: 500;
  width: 100%;
  font-size: 22px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .pInfoLabel .documentAdd img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .uploadFileDiv {
  display: flex;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #bab1b1;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .cancelDiv {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .inputFull {
  border-radius: 12px 0px 0px 12px;
  border: none !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .inputFull .MuiButton-root {
  padding-left: 6vw !important;
  border-radius: 12px 0px 0px 12px !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .MuiOutlinedInput-root {
  border-radius: 10px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .MuiOutlinedInput-root.Mui-focused {
  box-shadow: none;
  border: none !important;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .MuiOutlinedInput-input {
  padding: 16.5px 14px;
}
.UserEditProfile .outSide .PerosnalInformationFormInside .text-area {
  height: auto !important;
  margin-bottom: 0px !important;
  max-height: none;
}

.cardHeader {
  border-bottom: 2px solid #ffcb00;
  font-size: 24px;
  padding: 0px;
  padding-bottom: 10px;
  font-weight: 500;
  margin-bottom: 30px !important;
  color: #ffcb00;
}
.cardHeader.moderators {
  margin-top: 20px;
  border-color: #dcdcdc;
  text-align: center;
  color: #000000;
}

.pInfoText {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pInfotextField {
  border-radius: 12px;
  padding-bottom: 20px;
}

.pInfotextField fieldset {
  border-radius: 12px;
}

.pInfoCheckButtons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.areYouSureModal {
  display: flex;
  position: fixed;
  z-index: 1200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.areYouSureModal .backgroundDiv {
  margin: auto auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 12px;
  width: 400px !important;
  background-color: #fff;
}
.areYouSureModal .backgroundDiv .mainText {
  color: #aaa;
  font-weight: 500;
  width: 100%;
  font-size: 20px;
  margin-bottom: 25px;
}
.areYouSureModal .backgroundDiv .mainText .boldColored {
  color: #222255;
}
.areYouSureModal .backgroundDiv .yesNoBox {
  display: flex;
  width: 100%;
  grid-gap: 4%;
  gap: 4%;
}
.areYouSureModal .backgroundDiv .yesNoBox .yes {
  background-color: #222255;
  width: 48%;
  padding: 14px;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.areYouSureModal .backgroundDiv .yesNoBox .yes:hover {
  background-color: #0e082c;
}
.areYouSureModal .backgroundDiv .yesNoBox .no {
  cursor: pointer;
  font-size: 18px;
  border-radius: 12px;
  color: #aaa;
  font-weight: 500;
  border: 1px solid #cccbcb;
  background-color: #fff;
  padding: 14px;
  width: 48%;
  transition: 0.3s;
}
.areYouSureModal .backgroundDiv .yesNoBox .no:hover {
  background-color: #cccbcb;
}

@media only screen and (max-width: 900px) {
  .UserEditProfile {
    margin-top: 30px;
  }
  .UserEditProfile .editProfileBack {
    margin-left: 25px;
    font-size: 22px;
  }
  .UserEditProfile .saveChanges {
    width: 100%;
    margin: 16px 16px;
  }
  .UserEditProfile .outSide {
    margin-top: 30px;
  }
  .UserEditProfile .outSide .cardHeader {
    text-align: center;
    font-size: 22px;
  }
  .UserEditProfile .outSide .cardHeader {
    margin-bottom: 0px !important;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside label {
    font-size: 16px;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .earnPoint-para {
    width: 100%;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .checkInfoButton {
    width: 100%;
    font-size: 16px;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .small-label {
    font-size: 12px;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .fullForm {
    padding: 0px 16px;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .fullForm .pInfoLabel {
    font-size: 18px;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .subAndCountryBox {
    height: auto;
  }
  .UserEditProfile .outSide .PerosnalInformationFormInside .subAndCountryBox .selected-subcategories-wrapper {
    height: auto;
  }
}
.dropdown-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  min-width: 120px;
}

.pointer {
  cursor: pointer;
}

.dropdown-container * {
  width: 100%;
  box-sizing: border-box;
}

.dropdown-value {
  padding: 10px;
  font-weight: 400;
}

.dropdown-content-border {
  border-bottom: solid 1px #00000030;
}

.dropdown-content {
  padding: 10px;
  color: #7f8e9d;
  font-weight: 400;
  font-size: 1rem !important;
}

.dropdown-content:hover {
  background: #ccc;
}

.border-grey {
  border: 1px solid #00000030;
}

.dropdown-border {
  border: solid 1px #27272a;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-border .dropdown-icon {
  color: #27272a;
}

.dropdown-border-active {
  border: solid 1px #ffcb00;
  background-color: rgba(255, 203, 0, 0.1);
}

.dropdown-border-active .dropdown-icon {
  color: #ffcb00;
}

.dropdown-content-container {
  cursor: auto;
  color: #27272a;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 120px;
  width: 100%;
  z-index: 1;
  border: solid 1px #00000030;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  left: 0px;
}

.dropdown-content-scroll {
  max-height: 13rem;
  overflow: auto;
  text-align: start;
}

.block {
  display: block !important;
}

.justify_sb {
  display: flex;
  justify-content: space-between;
}

.flex_sb_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_sb_center > input {
  height: 50px;
  border: none;
  outline: none;
  font-size: 18px;
}

/* / / Chrome, Safari, Edge, Opera / / */
.flex_sb_center > input::-webkit-outer-spin-button,
.flex_sb_center > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / / Firefox / / */
.flex_sb_center > input[type="number"] {
  -moz-appearance: textfield;
}

.flex_sb_center > input:focus {
  color: black;
}

.country-code-div {
  border-right: solid #00000030 1px;
  border-radius: 0px 10px 10px 0px;
  max-width: -moz-max-content;
  max-width: max-content;
  color: #888;
}

.selected {
  background: rgba(0, 0, 0, 0.08);
  color: #000;
}

#Demoo {
  border-radius: 0px 0px 10px 10px;
  font-size: 20px;
}

.bottom-flat-border {
  border-radius: 10px 10px 0px 0px;
}

.phone-input {
  padding: 0 10px;
}

/* / ///////////////////////// / */

/* / / width / / */
::-webkit-scrollbar {
  width: 8px;
}

/* / / Handle / / */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* / / Handle on hover / / */
::-webkit-scrollbar-thumb:hover {
  background: #00000090;
}

.drop_down_flag_style {
  font-size: 30px;
}

.drop_down_countryName_style {
  font-size: 20px;
  padding: 0px 15px;
}

.refund-options {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.refund-options > div {
  flex: 1 1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
  border-radius: 8rem;
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.refund-options .active {
  border: solid 1px #ffcb00;
  background-color: rgba(255, 203, 0, 0.1);
}

.refund-options svg {
  margin-right: 1rem;
}

.refund-options p {
  color: #7f8e9d;
}

* {
    font-family: "Poppins" !important;
}

.react-tel-input {
    margin-top: 5px;
}

.cursor {
    cursor: pointer;
}

.react-tel-input .form-control {
    width: 100%;
}

.main-container {
    width: 1000px;
    border-radius: 15px;
    margin: 60px auto;
    overflow: hidden;
    height: -moz-max-content;
    height: max-content;
}

.img {
    width: 100%;
    margin: 0px auto;
}

.cancel-icon {
    margin: 16px !important;
    height: 45px;
    width: 45px;
    font-size: 35px !important;
    cursor: pointer;
}

.main-div {
    padding: 40px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.cancel-out {
    position: absolute;
    right: 0px;
    z-index: 1;
}

.heading_signup {
    padding: 0px;
    font-size: 36px;
    margin-top: 0px;
    font-weight: 500 !important;
}

.h2 {
    padding-bottom: 10px;
    font-size: 36px;
    margin-top: 0px;
}

.label {
    font-weight: 500;
    color: #150c44 !important;
}

.labul {
    margin-left: 20px;

}

.error {
    margin-left: 10px;
    color: red;
    display: block;
    font-size: 12px;
    font-weight: 500;
    min-height: 18px;
}

.checkbox-div {
    display: flex;
    /* align-items: flex-start; */
    /* margin-bottom: 20px; */
    /* flex-direction: column; */
    justify-content: center;
}

/* .checkbox {
    width: 18px;
    height: 30px;
    vertical-align: middle;
    margin-right: 7px;
} */

.terms {
    font-size: 13px;
    font-weight: 500;
    color: #97a4af;
}

.a1 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

/* .checkbox input{
    border-radius: 50% !important;
} */

.div-container .textField {
    margin: 9px 0px 25px !important;
    border-radius: 10px !important;
}

.checkbox-div .container {
    display: block;
    position: relative;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-bottom: 6px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 1;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: solid 1px #000000;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkbox-div input[type="checkbox"]::after {
    left: 27%;
    top: 11%;
    width: 37%;
    height: 69%;
}

.a2 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

.btn {
    width: 100%;
    color: #fff !important;
    background: #150c44 !important;
    font-size: 15px;
    margin: 15px 0px;
    border-radius: 10px;
    box-shadow: unset;
    text-transform: unset;
}

.h4 {
    margin: 30px;
    font-size: 20px;
    font-family: none;
    color: #00000059;
}

.a3 {
    color: #150c44;
    font-weight: bold;
}

.grid-image {
    background: #FFCB00;
    display: flex;
    align-content: center;
    align-items: center;
}

.image-logo {
    text-align: center;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 10px !important;
}

.MuiButton-root {
    text-transform: none !important;
    border-radius: 10px !important;
}

.inputFull {
    width: 100%;
    border: 1px solid #bab1b1;
    border-radius: 8px;
    opacity: 0.8;
    height: 50px;
    padding: 10px 10px;
    background-color: #FFFFFF;
    padding-left: 10px;
}
