.mainContainer {
  background-color: #fcfbfc;
  display: flex;
  background-color: #fcfbfc;
  padding-top: 80px;
  background: #fcfbfc;
  height: 100%;
}

.column1-main-box {
  text-align: center;
  padding: 1px 10px;
}

.column1-child-box1 {
  margin: auto;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

@media (max-width: 850px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

@media (max-width: 450px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

#profilePic {
  width: 70px;
  height: 70px;
}

#profilePic0 {
  width: 58px;
  height: 58px;
  margin-right: 15px;
}

.profileChildIcon {
  width: 18px;
}

.hearts {
  margin-top: 10px;
  color: #c4c6cb;
}

#report-popup {
  padding: 10px;
  margin: 0px;
  background-color: #f1f1f1;
}

#report-popup li {
  padding: 12px;
  border-bottom: #c1c1c1 solid 1px;
  list-style: none !important;
  min-width: 165px;
}

#report-popup li:last-of-type {
  border-bottom: none;
}

.hearts1 {
  margin-top: 15px;
  color: #c4c6cb;
}

.hearts2 {
  margin-top: 10px;
  color: #010039;
  font-weight: 700;
}

.column1-child-box1-clild1 {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

#profile-name {
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  color: #010039;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.slider0 {
  margin-top: -10px;
}

.slider0 .MuiSlider-track {
  height: 4px;
  display: block;
  position: absolute;
  border-radius: 3px;
  background-color: #fbca0c;
  color: #d9d9d9;
}

.slider0 .MuiSlider-rail {
  width: 100%;
  height: 4px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 4px;
  background-color: gray;
}

.slider0 .MuiSlider-thumb {
  position: initial !important;
}

.subcategories-box {
  justify-content: space-between;
  width: 100%;
}

#connected-btn {
  background-color: #010138;
  color: ffffff;
  height: 35px;
  border-radius: 16px;
  width: 100px;
}

.touch-points {
  color: #010138;
  font-weight: 500;
  cursor: pointer;
}

/* //middle box css */

.middle-box {
  border-radius: 15px;
  border: 1px solid #dbd8d8;
  background-color: white;
}

#middel-box-lable {
  word-break: break-word;
  font-size: 16px;
  font-weight: 500;
  color: #010039;
}

#middel-box-text {
  font-size: 16px;
  font-weight: 500;
  color: #707070;
}

/* THird column */

.right-side-btn {
  text-transform: none;
  margin-top: 30px;
  width: 90%;
  border: 1px solid #dbd8d8 !important;
  /* margin-left: 10px !important; */
}

.right-side-btn-div .MuiButton-root {
  border-radius: 12px !important;
  width: 100%;
  padding: 16px 16px 16px 10px !important;
  background: #fff !important;
}

.column1-main-box {
  padding: 1px 30px;
}

@media (max-width: 850) {
  .column1-main-box {
    padding: 1px 10px;
  }
}

@media (max-width: 450) {
  .column1-main-box {
    padding: 1px 5px;
  }
}

.column2-child-box2 {
  width: 90%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbarY.right {
  height: calc(100vh - 100px);
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden
}

.option_btn {
  font-weight: "bold";
  text-transform: capitalize;
  font-size: 18px;
  width: 200px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  padding: 10px;
}

.public-crd {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.crd-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #394c6a;
  line-height: 70px !important;
}

.flg_img {
  width: 30px;
  height: 20px;
  padding: 0;
  margin: 0;
}

/* room Content */
.profile_img {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.report-resource-popup-textfield .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
  font-size: 12px;
}

.room-details {
  background: #fff;
}


@media only screen and (max-width: 900px) {
  .column2-child-box2 {
    width: 98%;
    margin-left: 2% !important;
  }

  .mainContainer {
    padding: 20px 0px;
  }

  .scrollbarY.right {
    height: calc(100vh - 110px);
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #middel-box-lable {
    text-align: left;
  }
}