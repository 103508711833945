html,
body,
#root,
#root>div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.w18 {
  height: 18px;
  width: 18px;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}

.ptb25 {
  padding: 25px 10px;
}

.ptb30 {
  padding: 30px 15px;
}

.time-details {
  padding-top: 50px;
  padding-right: 15px;
}

.room-details-section .MuiContainer-maxWidthLg {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 100px;
}

.divider {
  border: 1px solid #c7cad9;
  height: 100%;
  width: 0px;
  position: relative;
  top: 66px;
}

.wd100 {
  width: 100%;
}

.wd80 {
  width: 80%;
}

.customFlex {
  display: flex;
  justify-content: space-between;
}

.room-details-bg-img {
  background: url(../../assets/Y.svg);
  height: 50%;
  width: 50%;
  position: absolute;
  z-index: -1;
  background-size: contain;
  top: 35%;
  left: 10%;
  background-repeat: no-repeat;
}
.room-details .customTypeBox {
  border: 1px solid #c7cad9;
  padding: 1px 7px;
  border-radius: 30px;
  margin-left: 5px;
}

.room-details-bg {
  background: url(../../assets/Y.svg);
  height: 100%;
  width: 22%;
  position: absolute;
  z-index: -1;
  background-size: contain;
  top: 10%;
  right: 6%;
  background-repeat: no-repeat;
}

.dFlexJustEnd {
  display: flex;
  justify-content: flex-end;
}

.upcom-img {
  position: relative;
  right: 18px;
}

.back_arrow {
  cursor: pointer;
  transition: all 0.4s ease;
}

.back_arrow:hover {
  scale: 1.2;
}

.rooms-details-calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calen-div {
  background: #f2f3f4;
  padding: 10px 13px;
  border-radius: 6px;
  margin-top: 7px;
}

.room-details-time {
  padding: 12px;
  border-radius: 8px;
  width: 45%;
  margin: 0 15px;
  display: flex;
}

.thumbsup {
  height: 12px;
  width: 15px;
}

.mr5 {
  margin-right: 5px;
}

.upcoming-section {
  padding: 8px 20px !important;
}

.pt20 {
  padding-top: 20px;
}

.mr15 {
  margin-right: 15px;
}

.w28 {
  height: 28px;
  width: 28px;
}

.upcoming-manage {
  flex-direction: column;
  padding: 20px 15px 20px 15px;
}

.standard-details {
  position: relative;
  /* top: 14px; */
}

.room-details-register textarea {
  /* border: 1px solid #c7cad9; */
  outline: none;
  resize: none;
  min-height: 113px !important;
  max-height: 130px !important;
  border-radius: 8px;
  margin-bottom: 15px;
  /* padding: 10px; */
}

.register-textarea {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px !important;
}

.register-textarea .MuiOutlinedInput-multiline {
  padding: 10px;
}

.room-details-register input {
  border: 1px solid #c7cad9;
  outline: none;
  resize: none;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 9px;
}

.add-resources img {
  height: 12px;
  width: 18px;
  margin-right: 5px;
}

.w18 {
  height: 18px;
  width: 18px;
}

.w24 {
  height: 24px;
  width: 24px;
}

.bTop {
  border-top: 1px solid #c7cad9;
}

.text-capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {}

@media only screen and (max-width: 1199px) and (min-width: 992px) {}

/* Register Modal CSS  */
.register-room {
  position: absolute;
  top: 70px;
  padding: 15px;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ffcb00;
  border-radius: 12px;
  width: 450px;
  height: auto;
  right: 15px;
  word-wrap: anywhere;
}

.register-room>div {
  overflow-y: auto;
  max-height: 90vh;
  padding-right: 0.5rem;
}

.part-resources {
  border: 1px solid #c7cad9;
  padding: 10px;
  border-radius: 8px;
}

/* Register Modal CSS  */

.borderYellow {
  border: 1px solid #ffcb00;
}

.p60 {
  padding: 60px 120px;
}

.h_300 {
  /* height: 300px; */
  /* width: 100%; */
}

.wb {
  word-break: break-all;
  white-space: pre-line;
}

.edit-box {
  width: 100%;
  padding: 0.5rem;
  min-height: 4rem;
  border-color: #ccc;
  border-radius: 0.5rem;
  outline: none;
}

.edit-box:hover {
  background: #ececec91;
}

.edit-box:active {
  background: #ececec91;
}

.edit-buttons button {
  border-radius: 0.3rem !important;
}

.edit-buttons button:last-child {
  background: #1e3354;
  color: #fff;
  margin-left: 0.5rem;
  width: 10rem;
}

.dotted-link-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.edit-resource-input input {
  padding: 5px 14px;
}

.back_arrow_mobile {
  display: none;
}

.backAndRoomDetail {
  margin-bottom: 10px;
}

.editUrlIconClass {
  top: 5px;
  right: 10px;
  position: absolute;
  height: 18px;
  font-size: 18px !important;
  color: rgb(174, 173, 173);
  cursor: pointer;
  z-index: 1
}

.customHostBox {
  margin-left: auto;
  margin-right: 0px;
  border-radius: 25px;
  padding: 2px 9px;
  background-color: #ffcb00;
}

.hostResources {
  margin-left: 1rem !important;
  position: relative;
  min-height: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {

  .room-details-section .MuiContainer-maxWidthLg {
    max-width: 1750px;
    padding-top: 10px;
    padding-left:5px;
    padding-right: 5px;
  }

  /* .hostResources {
    padding-top: 40px;
    margin-left: 0px !important;
    min-height: auto;
  } */

  .back_arrow {
    display: none;
  }

  .yourTakeAndResources {
    flex-wrap: wrap;
  }

  .yourTakeAndResources div:first-child {
    border: none !important;
  }

  .backAndRoomDetail {
    margin-top: 60px !important;
    display: flex;
    align-items: center;
    /* margin-bottom: 10px !important; */
  }

  .backAndRoomDetail h2 {
    margin-bottom: 0px !important;
  }

  .back_arrow_mobile {
    display: block;
  }

  .flexWrap {
    flex-wrap: wrap;
  }

  .register-room {
    border: none;
    width: auto;
    right: 0;
    height: 100%;
  }

  .register-room::-webkit-scrollbar,
  .register-room>div::-webkit-scrollbar {
    display: none;
  }

  .upcoming-section {
    /* padding: 0 !important; */
    padding:0px 20px !important;
  }
}

.UpcomingCustomFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.UpcomingCustomBottomFlex {
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: flex-end;
  justify-content: space-around;
}

.roomTypeName {
  font-size: 15px;
  font-weight: 500;
  color: #1e3354;
  padding:1px 7px;
  border-radius: 25px;
  border:1px solid #c7cad9;
}