/***roomEnding******/
#modal-window .MuiBackdrop-root {
    backdrop-filter: blur(4px);
}

#modal-window .modal-card {
    width: 19rem;
    position: absolute;
    top: 35%;
    right: 40%;
    border-radius: 8px;
}

.price-and-wallet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.blue-and-bold {
    color: #150c44;
    font-weight: 600;
}

.wallet {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 3px;
}

.card-text-modal {
    margin-bottom: 5px;
    color: #c0bfbf;
    font-size: .95rem;
}

#modal-window .card-button-modal {
    text-transform: none;
    border-radius: 8px;
    width: 50%;
    padding: 10px 0!important;
    margin-bottom: 5px;
}

@media only screen and (min-width:350px) and (max-width:600px ){
    #modal-window .modal-card{
        width: 15rem;
        top:35%;
        right: 18%;
    }
}